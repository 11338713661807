import Bus from '_u/bus'
import { Toast } from 'vant'

// ios 使用setupWebViewJavascriptBridge注册WebViewJavascriptBridge
// android直接注册在window中

// 方法名命名:
// H5页面调取APP方法以 'toApp' 开头
// APP页面调取H5方法以 'toWeb' 开头

class NativeAPI {
  setupWebViewJavascriptBridge(callback) {
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge)
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback)
    }
    window.WVJBCallbacks = [callback]
    const WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'https://__bridge_loaded__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(function () {
      document.documentElement.removeChild(WVJBIframe)
    }, 0)
  }

  /** h5调用app
   * @params api: 方法名
   * @params requestData: 传递的参数
   */
  callNativeApi(api, requestData) {
    if (!Bus.isApp) {
      Toast.fail('请使用App访问')
      return
    }
    return new Promise(resolve => {
      if (Bus.isAndroid) {
        if (!window.WebViewJavascriptBridge[api]) return resolve({ msg: `${api}方法未注册` })
        const res = requestData
          ? JSON.parse(window.WebViewJavascriptBridge[api](JSON.stringify(requestData)))
          : JSON.parse(window.WebViewJavascriptBridge[api]())
        console.log(api, res)
        resolve(res)
      } else {
        let callbacked = false
        this.setupWebViewJavascriptBridge(function (bridge) {
          bridge.callHandler(api, requestData, function responseCallback(res) {
            callbacked = true
            console.log(api, res)
            resolve(res)
          })
        })
        // 处理调用app未注册方法无响应问题
        setTimeout(() => {
          if (!callbacked) resolve({ msg: `${api}方法未注册` })
        }, 3000)
      }
    })
  }

  /** app调用h5
   * @params api: 方法名
   * @params fn: 处理函数
   */
  registerJsApi(api, fn) {
    if (Bus.isAndroid) window.WebViewJavascriptBridge[api] = fn
    else {
      this.setupWebViewJavascriptBridge(function (bridge) {
        bridge.registerHandler(api, function (data, responseCallback) {
          responseCallback(fn(data))
        })
      })
    }
  }
}

export default new NativeAPI()
