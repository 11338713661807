<template>
  <van-nav-bar
    fixed
    placeholder
    z-index="100"
    :border="false"
    :left-arrow="$route.name !== 'home'"
    @click-left="goback"
    :class="{
      'title-bold': $route.meta.titleBold,
      'main-bar': $route.meta.headerColor,
      'no-bg': $route.meta.noBg,
      'is-transparent': $route.meta.isTransparent,
      noDefinedLeft: ['sort'].includes($route.name),
      'has-search': ['home', 'sort'].includes($route.name)
    }"
    :style="{ '--color': color }"
  >
    <template #left v-if="$route.name === 'home'">
      <div class="nav-location van-ellipsis" @click.stop="switchLocation">
        <van-icon name="location-o" size="0.15rem" />{{ getCityCn(locationAddr) }}
      </div>
    </template>
    <template #title>
      <template v-if="!['home', 'sort'].includes($route.name)">{{ title }}</template>
      <van-search
        v-else
        shape="round"
        readonly
        placeholder="搜索商品"
        background="none"
        @click.stop="navigateToSearch"
      />
    </template>
    <template #right>
      <van-icon name="shopping-cart-o" size="0.2rem" @click="$router.push('/cart')" />
      <van-popover
        v-model:show="showPopover"
        :actions="actions"
        @select="onSelect"
        theme="dark"
        placement="bottom-end"
        class="nav-pop"
        :offset="[10, 8]"
      >
        <template #reference>
          <van-icon name="ellipsis" size="0.24rem" />
        </template>
      </van-popover>
    </template>
  </van-nav-bar>
</template>

<script>
import { NavBar, Search, Popover } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import { reactive, computed, toRefs, watch, toRaw, nextTick } from 'vue'
import Bus from '_u/bus'
import { getCityCn } from '_u/tool'
import { useStore } from 'vuex'
import NativeAPI from '_u/nativeAPI'

export default {
  components: {
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [Popover.name]: Popover
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const popover = reactive({
      navigationBarHeight: computed(() => store.state.user.navigationBarHeight),
      costomTitle: '',
      showPopover: false,
      color: 'transparent', // 透明色下的颜色变化
      actions: computed(() => {
        const actions = []
        if (store.state.app.pageFlag)
          actions.push({ text: '回到App', icon: 'revoke', pageFlag: true })
        if (route.name !== 'home') actions.push({ text: '首页', icon: 'wap-home-o', path: '/' })
        if (route.name !== 'sort') actions.push({ text: '分类', icon: 'cluster-o', path: '/sort' })
        if (route.name !== 'star') {
          actions.push({ text: '星级服务', icon: 'star-o', path: '/star' })
        }
        return [
          ...actions,
          { text: '我的订单', icon: 'orders-o', path: '/my/order' },
          { text: '个人中心', icon: 'user-o', path: '/my' }
        ]
      }),
      onSelect(action) {
        // 回到App关闭当前页面
        if (action.pageFlag) return NativeAPI.callNativeApi('toAppClosePage')
        router.push(action.path)
      },
      barStyle: computed(() => {
        const barStyle = {}
        if (route.meta.headerColor) barStyle.backgroundColor = '#fab400'
        return barStyle
      }),
      title: computed(() => {
        if (popover.costomTitle) return popover.costomTitle
        const titleKey = route.meta.titleKey
        if (titleKey) return route.meta.title[route.params[titleKey]]
        else return route.meta.title
      }),
      locationAddr: computed(() => store.state.app.locationAddr),
      switchLocation() {
        EventTrack('change_locate')
        router.push('/home/switchLocation')
      },
      navigateToSearch() {
        EventTrack('search_click')
        router.push('/home/search')
      },
      goback() {
        // 如果没有上一页则返回首页
        if (router.options.history.state.back && !route.meta.back) router.back()
        // 没有上一页 且是从App内页进入 则直接返回App
        else if (store.state.app.pageFlag) return NativeAPI.callNativeApi('toAppClosePage')
        else router.replace('/')
      }
    })
    // 导航栏变色
    Bus.$on('navChange', color => {
      popover.color = color
    })
    // 自定义title
    Bus.$on('costom-title', title => {
      popover.costomTitle = title
    })
    // 重置自定义title
    watch(
      () => toRaw({ ...route }),
      () => {
        if (popover.costomTitle) popover.costomTitle = ''
      },
      { deep: true }
    )

    // 修改导航栏的上边距
    watch(
      () => popover.navigationBarHeight,
      async nVal => {
        await nextTick()
        // 修改导航栏悬浮部分的内边距
        const navBarElement = document.querySelector('.van-nav-bar--fixed')
        navBarElement.style.paddingTop = nVal + 'px'
        // 修改导航栏占位部分的高度
        const navBarBodyElement = document.querySelector('.van-nav-bar__placeholder')
        const appBarHeight = Big(navBarBodyElement.style.height.replace('px', '') || 0)
          .plus(nVal)
          .toFixed(4)
        navBarBodyElement.style.height = `${appBarHeight}px`
      },
      { immediate: true }
    )

    return { ...toRefs(popover), getCityCn }
  }
}
</script>

<style lang="less" scoped>
.main-bar {
  &.noDefinedLeft {
    :deep(.van-nav-bar__title) {
      max-width: 70%;
      margin: 0 0 0 90rpx;
    }
  }
  :deep(.van-nav-bar) {
    background-color: #fab400;
    &__left {
      .nav-location {
        max-width: 130rpx;
        text-align: left;
        .van-icon {
          top: 6rpx;
        }
      }
    }
    &__title {
      overflow: visible;
    }
    .van-search {
      // margin-left: calc(-40% + 24rpx);
      height: var(--van-nav-bar-height);
      .van-cell {
        height: 64rpx;
      }
    }
  }
}
.no-bg {
  height: 0px !important;
  :deep(.van-nav-bar) {
    background: rgba(0, 0, 0, 0);
  }
}
.title-bold {
  :deep(.van-nav-bar) {
    &__title {
      font-weight: bold;
    }
  }
}
// 透明底色
.is-transparent {
  :deep(.van-nav-bar) {
    background-color: var(--color);
  }
}
.has-search {
  :deep(.van-nav-bar__title) {
    height: 100%;
    .van-search {
      padding-top: 10rpx;
    }
  }
}
:deep(.van-popover__wrapper) {
  margin-left: 20rpx;
  .van-icon {
    vertical-align: middle;
  }
}

// 首页地址展示遮盖处理
:deep(.van-nav-bar__left) {
  max-width: 20%;
  padding-right: 0;
}
</style>
<style lang="less">
.nav-pop {
  // 调整三角形位置
  &.van-popover[data-popper-placement='bottom-end'] .van-popover__arrow {
    right: 16rpx;
  }
}
</style>
