export default {
  getPartnerList: {
    title: '好礼合伙人列表',
    url: '/api/shop/activity/partner/list'
  },
  getPartnerActivityDetail: {
    title: '组团活动详情',
    url: '/api/shop/activity/team/detail'
  },
  getPartnerActivityRule: {
    title: '组团活动规则',
    url: '/api/shop/activity/team/rule'
  },
  getPartnerActivityPrize: {
    title: '组团活动-奖品说明',
    url: '/api/shop/activity/team/prize_desc'
  },
  getPartnerActivityMyPrize: {
    title: '组团活动-我的奖品',
    url: '/api/shop/activity/team/my_prize'
  },
  getPartnerMyIntegral: {
    title: '组团活动-我的积分',
    url: '/api/shop/activity/team/my_integral'
  },
  getJoinTeamConfig: {
    title: '组团活动-获取入团配置',
    url: '/api/shop/activity/team/getJoinTeamConfig'
  },
  checkJoinTeamCondition: {
    title: '组团活动-检查入团资格',
    // url: '/api/shop/activity/team/checkJoinQualification' // 旧的查询接口
    url: '/api/shop/activity/team/joinCheck',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  joinPartnerTeam: {
    title: '组团活动-加入团队',
    // url: '/api/shop/activity/team/joinTeam',
    url: '/api/shop/activity/team/join',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPartnerTotalRank: {
    title: '组团活动-总排名',
    url: '/api/shop/activity/team/rank',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPartnerTeamRank: {
    title: '组团活动-团队排名',
    url: '/api/shop/activity/team/rank_team',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  createTeam: {
    title: '创建我的队伍',
    url: '/api/shop/activity/team/create',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  updateTeam: {
    title: '修改团队名称',
    url: '/api/shop/activity/team/update',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPartnerTaskConfig: {
    title: '组团活动-查询任务参数配置和完成情况',
    url: '/api/shop/activity/team/getTaskConfig'
  },
  getPartnerVideoDetail: {
    title: '组团活动-获取视频信息',
    url: '/api/shop/activity/team/getVideoDetail'
  },
  completeVideoTask: {
    title: '组团活动-完成视频任务',
    url: '/api/shop/activity/team/completeVideoTask',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPartnerGoodList: {
    title: '组团活动-获取浏览商品列表',
    url: '/api/shop/goods/listForTask'
  },
  getGoodsTaskAchievement: {
    title: '组团活动-查询商品任务查看历史',
    url: '/api/shop/activity/team/getGoodsTaskAchievement'
  },
  completeGoodsTask: {
    title: '组团活动-完成浏览商品任务',
    url: '/api/shop/activity/team/completeGoodsTask',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  checkHelpCondition: {
    title: '组团活动-检查助力资格',
    url: '/api/shop/activity/team/checkHelpQualification'
  },
  completeHelpTask: {
    title: '组团活动-给好友助力',
    url: '/api/shop/activity/team/completeHelpTask',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPartnerTaskStatus: {
    title: '组团活动-查询组团任务信息',
    url: '/api/shop/activity/team/taskStatus'
  },
  getPartnerChannelCode: {
    title: '组团活动-获取添加企微图片',
    url: '/api/shop/activity/team/channelCode'
  },
  getPartnerEndPrize: {
    title: '组团活动-活动结束奖品弹窗',
    url: '/api/shop/activity/team/endPrize'
  },
  leaveTeamCheck: {
    title: '退出团队校验',
    url: '/api/shop/activity/team/quitCheck',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  leaveTeam: {
    title: '退出团队',
    url: '/api/shop/activity/team/quit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  videoTaskList: {
    title: '获取视频列表',
    url: '/api/shop/activity/team/videoTask'
  },
  watchVideoNumber: {
    title: '完成视频号任务',
    url: '/api/shop/activity/team/watchVideoNumber',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  shareFriends: {
    title: '分享朋友圈',
    url: '/api/shop/activity/team/shareFriends'
  },
  jackpotPrizes: {
    title: '奖池瓜分奖品信息',
    url: '/api/shop/activity/team/jackpotPrizes'
  },
  teamGoodStatus: {
    title: '商品状态',
    url: '/api/shop/activity/team/goodStatus'
  },
  checkTaskCompletedOrNot: {
    title: '校验任务是否已完成',
    url: '/api/shop/activity/team/checkTaskCompletedOrNot',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  }
}
