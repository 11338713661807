import BaseLayout from '@/layouts/BaseLayout'

export default [
  {
    name: 'starTab',
    path: '/star',
    component: BaseLayout,
    meta: {
      title: '星级服务'
    },
    children: [
      {
        name: 'star',
        path: '/star',
        component: () => import('_p/star'),
        meta: {
          title: '星级服务',
          titleBold: true,
          noBg: true
        }
      }
    ]
  }
]
