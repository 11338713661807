import Moment from 'moment'

export const moment = (time, type = 1) => {
  let format
  if (type === 1) format = 'YYYY-MM-DD HH:mm:ss'
  else if (type === 2) format = 'YYYY-MM-DD'
  else if (type === 3) format = 'HH:mm:ss'
  else format = type
  const result = Moment(time).format(format)
  return result !== 'Invalid date' ? result : '--'
}
export default Moment
