/*
 * @Author: tanglinshan
 * @Date: 2023-06-08 17:09:06
 * @LastEditors: tanglinshan
 * @LastEditTime: 2023-06-09 13:08:35
 * @Description: 路由相关
 */

import clonedeep from 'lodash.clonedeep'
const STATE = {
  cacheList: []
}

const order = {
  state: clonedeep(STATE),
  mutations: {
    // 存放需要缓存的路由
    SET_CACHE_LIST(state, name) {
      state.cacheList.push(name)
    },
    // 移除缓存中的指定路由
    DEL_CACHE_LIST(state, name) {
      if (state.cacheList.includes(name)) {
        state.cacheList = state.cacheList.filter(item => item !== name)
      }
    }
  }
}

export default order
