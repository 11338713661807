<template>
  <router-view />
</template>

<script>
import { useRoute } from 'vue-router'
import { watch, toRaw, reactive } from 'vue'
import Request from '_api'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    // 初始化轮播配置
    store.dispatch('GetWheelSeeding')
    // 记录窗口高度，目的是为了解决Safari和Chrom100vh高度不准确问题
    const appHeight = () => {
      const ratio = window.screen.width / window.screen.height
      // 根据屏宽比调整最大宽度
      if (ratio < 1) {
        const htmlDom = document.querySelector('html')
        htmlDom.style.setProperty('max-width', '100%')
        htmlDom.style.setProperty('font-size', '26.6666vw')
      }
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
      doc.style.setProperty('--app-nav-bar-height', `${store.state.user.navigationBarHeight}px`) // app出让导航栏的高度（刘海屏部分）
    }
    window.addEventListener('resize', appHeight)
    appHeight()

    const route = useRoute()
    const app = reactive({
      theme: undefined,
      async getTheme() {
        const res = await Request.getTheme({ apiReject: true })
        if (res.code === 0) {
          app.theme = res.data.color
          app.changeTheme(route.name)
        }
      },
      changeTheme(name) {
        if (name === 'home' && app.theme === 'gray') {
          document.documentElement.style.filter = 'grayscale(100%)'
        } else document.documentElement.style.filter = 'unset'
      }
    })
    app.getTheme()
    watch(
      () => toRaw({ ...route }),
      async newRoute => {
        app.changeTheme(newRoute.name)

        // 存放需要缓存的路由name
        if (store.state.menu.cacheList.includes(newRoute.name)) return
        if (newRoute.meta.keepAlive) {
          store.commit('SET_CACHE_LIST', newRoute.name)
        }
      },
      { immediate: true, deep: true }
    )
  }
}
</script>

<style lang="less">
@import './assets/style/base.less';
</style>
