// api请求配置
export const apiConfig = {
  cid: 20,
  v: 1
}

export const lblApiConfig = {
  seq: 1,
  version: 'V1.0'
}

// 环境变量
let env = 'pro'
if (process.env.NODE_ENV === 'development') env = 'local'
else if (/154:8004/.test(location.host)) env = 'dev1'
else if (/91:8004/.test(location.host)) env = 'dev2'
else if (/131:8004/.test(location.host)) env = 'dev3'
else if (/crmtest1/.test(location.host)) env = 'test1'
else if (/crmtest2/.test(location.host)) env = 'test2'
else if (/crmtest3/.test(location.host)) env = 'test3'
else if (/crmpre/.test(location.host)) env = 'pre'

export const baseENV = env
console.log('baseENV', baseENV)

// 请求地址
const baseUrlList = {
  // 本地环境
  local: '',
  // 开发环境
  dev1: 'http://10.160.0.66',
  dev2: 'http://10.160.0.26',
  dev3: 'http://iotdev-ad.leelen.net',
  // 测试环境
  test1: 'https://iotst-sq.leelen.net',
  test2: 'https://iottest.leelen.net',
  test3: 'https://iotst-ad.leelen.net',
  // 预生产环境
  pre: 'https://iotpre.leelen.net',
  // 生产环境
  pro: 'https://iot.leelen.com'
}
// 客服h5调整地址
const sevH5UrlList = {
  // 开发环境
  local: 'http://10.100.24.136:8092',
  // 开发环境
  dev1: 'http://10.173.173.154:8005',
  dev2: 'http://10.160.0.91:8005',
  dev3: 'http://10.160.0.131:8005',
  // 测试环境
  test1: 'https://sevapp.leelen.net',
  test2: 'https://crmtest2-home.leelen.net',
  test3: 'https://crmtest3-home.leelen.net',
  // 预生产环境
  pre: 'https://crmpre-home.leelen.net',
  // 生产环境
  pro: 'https://home.leelen.com'
}

export const BASE_URL = baseUrlList[baseENV]
export const sevH5Url = sevH5UrlList[baseENV]
console.log('BASE_URL', BASE_URL)
console.log('sevH5Url', sevH5Url)

// 小程序环境类型枚举
// 1-开发版 2-体验版 0-正式版
// 开发||测试环境: 开发版, 预生产环境: 体验版, 正式环境: 正式版
const miniTypeEnum = {
  local: 1,
  dev1: 1,
  dev2: 1,
  dev3: 1,
  test1: 1,
  test2: 1,
  test3: 1,
  pre: 2
}
const miniProgramType = miniTypeEnum[baseENV] || 0
console.log('miniProgramType:', miniProgramType)

export const miniProgramInfo = {
  userName: 'gh_d2de785d779e', // 商城小程序原始id
  appid: 'wx3b7400cdb8fc4cbb', // 商城小程序appid
  webpageUrl: 'https://leelen.net', // 兼容微信低版本的网页链接(已与产品沟通, 放的是商城主页)
  miniProgramType,
  desc: ''
}

// 分销-审核状态
export const approveStatusList = [
  { label: '认证升级', value: 0 }, // 未认证
  { label: '认证审核中', value: 1 }, // 审核中
  { label: '认证升级', value: 2 }, // 审核成功
  { label: '认证失败', value: 3 } // 审核失败
]

// 分销-订单状态
export const orderStatusList = [
  { label: '全部', value: 9 },
  { label: '待结算', value: 0 },
  { label: '已结算', value: 1 },
  { label: '失效', value: 5 }
]
