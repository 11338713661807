import clonedeep from 'lodash.clonedeep'
import Request from '_api'

const STATE = {
  // 首页活动弹窗
  homePopupList: [],
  // 首页优惠券弹窗
  homePopCoupons: [],
  init: false, // 初始化标识
  homeTip: false, // 首页显示tip标识
  contrast: false, // 商品详情页展示比价弹窗
  pageFlag: null, // 页面标识 1:App内页进入商城 前端根据这个设置返回App操作
  locationAddr: {}, // 定位城市地址--province和district在切换城市时会为空{ province, city, district, cityCode }
  wheelSeeding: {
    commercial: 3000, // 商品轮播配置
    banner: 3000 // banner轮播配置
  }
}

const app = {
  state: clonedeep(STATE),
  getters: {
    // 首页弹窗item
    popList(state, { isLogin }) {
      let popList = state.homePopupList
      if (!isLogin) {
        // 未登录状态下过滤状态
        popList = popList.filter(item => !item.condity)
      }
      return popList
    }
  },
  mutations: {
    SET_HOMEPOPUP(state, list) {
      state.homePopupList = list
    },
    SHIFT_HOMEPOPCOUPONS(state, el) {
      state.homePopupList = state.homePopupList.filter(item => item.id !== el.id)
    },
    SET_HOMEPOPCOUPONS(state, list = []) {
      state.homePopCoupons = list
    },
    SET_HOMETIP(state, status = true) {
      state.homeTip = status
    },
    SET_INIT(state, status = true) {
      state.init = status
    },
    SET_LOCATIONADDR(state, payload = {}) {
      state.locationAddr = payload
    },
    SET_CONTRAST(state, contrast = {}) {
      state.contrast = contrast
    },
    // 重置state
    RESET(state) {
      Object.assign(state, clonedeep(STATE), { locationAddr: state.locationAddr })
    },
    SET_WHEELSEEDING(state, wheelSeeding) {
      state.wheelSeeding = wheelSeeding
    },
    SET_PAGEFLAG(state, flag) {
      state.pageFlag = flag
    }
  },
  actions: {
    async GetWheelSeeding({ commit }) {
      // 获取轮播配置
      const res = await Request.getWheelSeeding()
      if (res.code === 0) {
        commit('SET_WHEELSEEDING', {
          commercial: res.data?.commercialWheelSeeding || 0, // 商品轮播配置
          banner: res.data?.bannerWheelSeeding || 0 // banner轮播配置
        })
      }
    }
  }
}

export default app
