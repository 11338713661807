import { createVNode, render, cloneVNode } from 'vue'
import LoginNode from './index.vue'
import app from '@/main'

let VNode

/**
 * login弹窗调用
 * @param {*} path 登录后需要调整的页面，不传则不跳转
 * @returns
 */
const Login = (path = null, afterLogin = null) => {
  if (VNode) {
    VNode = cloneVNode(VNode, { path, afterLogin }, null)
    VNode.component.proxy.show = true
  } else {
    VNode = createVNode(LoginNode, { path, afterLogin }, null)
    VNode.appContext = app._context
  }
  render(VNode, document.body)
}

export default Login
