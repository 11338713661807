import { BASE_URL } from '@/config'

export default {
  appLogin: {
    title: 'app登录',
    method: 'post',
    gate: 'lbl',
    url: BASE_URL + '/rest/app/community/shop/login',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getUserInfo: {
    title: '获取用户信息',
    url: '/api/shop/userInfo'
  },
  unBind: {
    title: '解绑',
    method: 'post',
    url: '/api/shop/user/unbind'
  },
  sendCode: {
    title: '发送验证码',
    method: 'post',
    url: '/api/shop/sendCode'
  },
  bind: {
    title: '验证码绑定手机号',
    method: 'post',
    url: '/api/shop/loginWithSmsCode'
  },
  listReceiverInfo: {
    title: '获取用户的收货地址列表',
    method: 'get',
    url: '/api/shop/receiver/listReceiverInfo'
  },
  getReceiverInfoDetail: {
    title: '获取收货地址详情',
    method: 'get',
    url: '/api/shop/receiver/getReceiverInfoDetail'
  },
  receiverInsert: {
    title: '新增收货地址',
    method: 'post',
    url: '/api/shop/receiver/insert'
  },
  receiverUpdate: {
    title: '更新收货地址',
    method: 'post',
    url: '/api/shop/receiver/update'
  },
  receiverDelete: {
    title: '删除收货地址',
    method: 'post',
    url: '/api/shop/receiver/delete',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getCityCode: {
    title: '获取城市code',
    method: 'get',
    url: '/api/shop/position/city/code'
  },
  getSettlementReceiver: {
    title: '获取结算默认收货地址',
    method: 'post',
    url: '/api/shop/order/getSettlementReceiver'
  },
  positionData: {
    title: '获取行政区划信息',
    method: 'get',
    url: '/api/shop/position/data'
  },
  getWheelSeeding: {
    title: '轮播参数查询',
    url: '/api/shop/order/getWheelSeeding'
  },
  checkIsDistributor: {
    title: '是否是分销者',
    url: '/api/shop/page/isDistributor'
  }
}
