export default {
  getSpecs: {
    title: '获取购物车中商品的规格',
    url: '/api/shop/cart/getProduct'
  },
  addCart: {
    title: '添加商品到购物车',
    method: 'post',
    url: '/api/shop/cart/add'
  },
  getCartList: {
    title: '获取购物车列表数据',
    url: '/api/shop/cart/listCartItems'
  },
  updateCartAttr: {
    title: '修改购物车商品规格',
    method: 'post',
    url: '/api/shop/cart/updateCartItemAttr'
  },
  updateCartItemQuantity: {
    title: '修改购物车商品数量',
    method: 'post',
    url: '/api/shop/cart/updateCartItemQuantity'
  },
  deleteCartItem: {
    title: '删除购物车商品',
    url: '/api/shop/cart/deleteCartItem'
  },
  getServiceRules: {
    title: '获取服务费规则系数',
    method: 'post',
    url: '/api/shop/config/param/servicePriceRulesConfigValue'
  }
}
