<template>
  <van-dialog
    id="loginDialog"
    v-model:show="show"
    title="登 录"
    show-cancel-button
    confirmButtonText="登录"
    theme="round-button"
    :before-close="beforeClose"
    @close="close"
  >
    <van-form ref="form" label-width="0.2rem">
      <van-field
        v-model="params.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入手机号"
        :rules="rules.phone"
        clearable
        name="phone"
        left-icon="contact"
      >
      </van-field>
      <van-field
        v-model="params.smsCode"
        type="text"
        maxlength="4"
        placeholder="请输入短信验证码"
        :rules="rules.smsCode"
        clearable
        name="smsCode"
        left-icon="envelop-o"
      >
        <template #button>
          <van-button size="small" type="link" @click="getCaptcha" :disabled="state.await">{{
            !state.await ? '获取验证码' : state.countDown.current.seconds + ' s'
          }}</van-button>
        </template>
      </van-field>
    </van-form>
  </van-dialog>
</template>

<script>
import Request from '_api'
import storage from 'store'
import { Toast } from 'vant'
import { reactive, toRefs, ref } from 'vue'
import { matchMobile } from '_u/regExp'
import { useCountDown } from '@vant/use'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  props: {
    path: {
      type: String,
      default: null
    },
    afterLogin: {
      type: Function,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const login = reactive({
      form: ref(),
      show: true,
      params: {
        phone: storage.get('PHONE') || '',
        smsCode: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号' },
          { pattern: matchMobile, message: '手机号格式错误' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码' },
          { pattern: /\w{4,4}/, message: '请输入四位验证码' }
        ]
      },
      state: {
        await: false,
        countDown: useCountDown({
          time: 60 * 1000,
          onFinish() {
            login.state.await = false
            login.state.countDown.reset()
          }
        })
      },
      // 获取验证码
      async getCaptcha() {
        login.form.validate('phone').then(async () => {
          storage.set('PHONE', login.params.phone)
          const res = await Request.sendCode({ phone: login.params.phone })
          if (res.code === 0) {
            login.params.smsCode = ''
            Toast(res.msg)
            login.state.countDown.start()
            login.state.await = true
          }
        })
      },
      beforeClose(action) {
        if (action === 'confirm') {
          return login.form.validate().then(
            async () => {
              // 登录
              const res = await Request.bind(login.params)
              if (res.code === 0) {
                store.commit('SET_TOKEN', res.data.sid)
                store.commit('SET_USERINFO', res.data)
                // 获取用户地址
                store.dispatch('GetAddressList')
                // 获取分销信息
                store.dispatch('GetDistributionInfo')
                return true
              } else return false
            },
            () => {
              return false
            }
          )
        } else return true
      },
      close() {
        login.params = {
          phone: storage.get('PHONE') || '',
          smsCode: ''
        }
        login.form.resetValidation()
        if (store.state.user.token && props.path) router.push(props.path)
        if (store.state.user.token && props.afterLogin && typeof props.afterLogin === 'function')
          props.afterLogin()
      }
    })

    return {
      ...toRefs(login)
    }
  }
}
</script>

<style lang="less" scoped>
.van-form {
  padding: 0 30rpx;
}
</style>
