<template>
  <!-- 导航栏 -->
  <!-- 如果是小程序，并且携带hideheaderbar=1参数，则不显示头部，此参数需要考虑页面显示区域高度问题 -->
  <header-bar v-if="$route.query.hideHeaderbar !== '1'" />

  <router-view v-slot="{ Component }">
    <transition name="slide-fade" mode="out-in">
      <div id="content">
        <!-- :key="$route.fullPath" -->
        <keep-alive :include="$store.state.menu.cacheList">
          <component :is="Component" :key="$route.name" />
        </keep-alive>
      </div>
    </transition>
  </router-view>
  <!-- 页脚 根据EDI要求，页脚添加备案号-->
  <div class="leelen-footer norem" :class="{ noft: $route.meta.nofooter }" v-if="!$bus.isMobile">
    <div>©厦门立林科技有限公司版权所有</div>
    <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">闽ICP备09020435号</a>
    <a
      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35021102001171"
      target="_blank"
      rel="nofollow"
      style="margin-left: 5px"
      >闽公网安备35021102001171号</a
    >
  </div>
</template>

<script>
import HeaderBar from './components/HeaderBar'

export default {
  components: {
    HeaderBar
  }
}
</script>

<style lang="less" scoped>
#content {
  // overflow: hidden;
}
// 路由过度
.slide-fade {
  &-enter {
    opacity: 0;
    transform: translateX(10px);
  }
  &-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }
  &-enter-active,
  &-leave-active {
    transition: all 0.2s ease;
  }
}
</style>
<style lang="less">
.leelen-footer.norem {
  width: 100%;
  height: 38px;
  padding: 5px 0;
  height: calc(38px + constant(safe-area-inset-bottom)); // IOS<11.2
  height: calc(38px + env(safe-area-inset-bottom)); // IOS>11.2
  line-height: 1.3;
  background-color: #e6e6e6;
  text-align: center;
  font-size: 12px;
  color: #999;
  &.noft {
    height: 0;
    padding: 0;
    overflow: hidden;
  }
}
</style>
