export default {
  getActivityUp: {
    title: '活动弹窗显示',
    url: '/api/shop/upAd/listUpAd'
  },
  getActivityList: {
    title: '好礼共享列表查询',
    url: '/api/shop/activity/list'
  },
  getActivityDetail: {
    title: '活动界面详情',
    url: '/api/shop/activity/launch/detail'
  },
  getActivityRule: {
    title: '活动规则',
    url: '/api/shop/activity/getRule'
  },
  getActivityHistory: {
    title: '活动历史',
    url: '/api/shop/activity/getHistory'
  },
  getActivityPrize: {
    title: '查询活动所有奖品',
    url: '/api/shop/activity/queryPrize'
  },
  getActivityResult: {
    title: '查询助力结果--发起人、助力人',
    url: '/api/shop/activity/joinActivity'
  },
  launchActivity: {
    title: '发起助力',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/api/shop/activity/launch'
  },
  getPromotionPriceGoodsPage: {
    title: '秒杀活动商品分页',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/api/shop/promotion/promotionPrice/goodsPage'
  },
  getPromotionPriceList: {
    title: '秒杀活动列表',
    url: '/api/shop/promotion/promotionPrice/list'
  }
}
