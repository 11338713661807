/*
 * @Author: tanglinshan
 * @Date: 2024-01-09 18:25:03
 * @LastEditors: tanglinshan
 * @LastEditTime: 2024-01-15 13:49:27
 * @Description: 拼团活动相关接口
 */
export default {
  groupBuyingConfig: {
    title: '拼团规则配置详情',
    url: '/api/shop/groupBuyingConfig/detail'
  },
  getGroupBuyingList: {
    title: '拼团活动列表',
    url: '/api/shop/groupBuying/list'
  },
  getGroupBuyingGoodsPage: {
    title: '拼团活动商品分页',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/api/shop/groupBuying/goodsPage'
  },
  getGroupBuyingRecordPage: {
    title: '拼团记录 - 列表',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/api/shop/team/record/queryPage'
  },
  getGroupBuyingRecordDetail: {
    title: '拼团记录 - 详情',
    method: 'get',
    url: '/api/shop/team/record/details'
  },
  getGroupBuyingShareDetail: {
    title: '分享页 - 详情',
    method: 'get',
    url: '/api/shop/team/record/shareDetails'
  },
  unOpenActivityAuth: {
    title: '非公开活动授权用户',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/api/shop/groupBuying/unOpenActivityAuth'
  },
  shareVerify: {
    title: '分享页 - 入团校验',
    method: 'get',
    url: '/api/shop/team/record/shareVerify'
  },
  getCityList: {
    title: '分享页 - 选择城市',
    method: 'get',
    url: '/api/shop/team/record/selectCity'
  },
  getGroupBuyingInvite: {
    title: '邀请拼团',
    url: '/api/shop/groupBuying/invite'
  },
  validActivity: {
    title: '立即开团-校验结果',
    url: '/api/shop/groupBuying/validActivity'
  },
  getGroupBuyingGroupResult: {
    title: '拼团记录 - 拼团结果',
    method: 'get',
    url: '/api/shop/groupBuying/groupResult'
  }
}
