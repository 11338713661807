export default {
  createOrder: {
    title: '创建订单',
    method: 'post',
    url: '/api/shop/order/createOrder',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getGoodsInfoForOrder: {
    title: '填写订单页面获取商品信息',
    method: 'post',
    url: '/api/shop/order/listGoodsInfoForOrder',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getSettlementInfo: {
    // v1.3.0新增 替换getGoodsInfoForOrder接口
    title: '订单结算页',
    method: 'post',
    url: '/api/shop/order/getSettlementInfo',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPresentAmount: {
    // v1.3.2新增 获取赠送金
    title: '订单结算页',
    method: 'post',
    url: '/api/shop/order/getPresentAmount',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getOrderList: {
    title: '我的 - 我的订单',
    url: '/api/shop/order/listOrderByStatus'
  },
  getOrderNum: {
    title: '获取 我的 - 各订单数量',
    url: '/api/shop/order/getUserOrderNum'
  },
  getOrderDetail: {
    title: '获取订单详情',
    url: '/api/shop/order/getOrderDetail'
  },
  cancelOrder: {
    title: '取消订单',
    url: '/api/shop/order/cancelOrder'
  },
  getOrderCancelList: {
    title: '获取订单取消原因列表',
    url: '/api/shop/order/listCancelReason'
  },
  checkConfirmReceipt: {
    title: '校验是否允许确认收货',
    url: '/api/shop/order/checkConfirmReceipt'
  },
  confirmReceipt: {
    title: '确认收货',
    url: '/api/shop/order/confirmReceipt'
  },
  getWaitForCommentGoods: {
    title: '订单 - 选择评价商品 - 待评价商品列表',
    url: '/api/shop/order/listWaitForCommentGoods'
  },
  getLogisticsList: {
    title: '物流详情查询',
    url: '/api/shop/shoplogistics/detail'
  },
  getLogisticsMulList: {
    title: '订单物流列表查询',
    url: '/api/shop/order/listForLogistics'
  },
  submitComment: {
    title: '商品评价提交',
    method: 'post',
    url: '/api/shop/order/submitComment',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  buyGoodsAgain: {
    title: '再次购买', // 将该商品再次加入购物车
    method: 'post',
    url: '/api/shop/order/buyGoodsAgain'
  },
  getRefundDetail: {
    title: '获取退款详情',
    url: '/api/shop/order/getRefundDetail'
  },
  payforOrder: {
    title: '再次支付订单',
    url: '/api/shop/order/payOrderNew'
  },
  getPayResult: {
    title: '获取支付结果',
    url: '/api/shop/order/getPayResult'
  },
  getInventoryList: {
    title: '终端机生成的清单详情',
    url: '/api/shop/order/detail'
  },
  getMinServiceCharge: {
    title: '获取最低服务费差价',
    url: '/api/shop/getMinServiceCharge'
  },
  shoppingCouponPrompt: {
    title: '获取订单购物赠券提示',
    url: '/api/shop/order/shoppingCouponPrompt',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  returnOrderItemSelector: {
    title: '退款-选择要申请售后的订单明细数据',
    url: '/api/shop/afs/service/orderItem/selector',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  returnOrderItemCheck: {
    title: '退款-校验选择要申请售后的订单明细数据',
    url: '/api/shop/afs/service/orderItem/selector/check',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getReturnServiceReason: {
    title: '退款-获取申请原因列表',
    url: '/api/shop/afs/service/reason'
  },
  getReturnExplain: {
    title: '退款-获取退货说明',
    url: '/api/shop/afs/service/return_explain'
  },
  returnOrderItemProductSelector: {
    title: '退款-获取要申请售后的产品明细数据',
    url: '/api/shop/afs/service/orderItemProduct/selector',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  saveServiceApply: {
    title: '退款-提交售后申请表单',
    url: '/api/shop/afs/service/apply/save',
    method: 'post',
    timeout: 35000,
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getOrderAfterSalesRecordList: {
    title: '退款-小程序/h5 售后申请记录列表',
    url: '/api/shop/afs/service/getOrderAfterSalesRecordList'
  },
  getOrderAfterSalesFlowList: {
    title: '退款-小程序/h5 售后历史',
    url: '/api/shop/afs/service/getOrderAfterSalesFlowList'
  },
  getOrderAfterSalesProductList: {
    title: '退款-小程序/h5 售后详情',
    url: '/api/shop/afs/service/getOrderAfterSalesProductList'
  },
  withdrawServiceRecall: {
    title: '退款-小程序/h5 售后详情-用户撤回',
    url: '/api/shop/afs/service/recall'
  },
  getRefundDetailById: {
    title: '小程序/h5 售后详情-交易取消-退款详情',
    url: '/api/shop/afs/service/getRefundDetailById'
  }
}
