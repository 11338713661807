import { moment } from './moment'
import Request from '_api'
import NativeAPI from '_u/nativeAPI'
import Bus from '_u/bus'
import store from '@/store'
import Login from '_c/login'
import router from '@/router'

/**
 * 过去时间显示转换函数（非过去时间会转换成*年*月*日）
 * @param {Number} timeStamp 要转换的时间戳
 * @return 1分钟以内显示-刚刚；10分钟以内显示-X分钟前；2天内，当天显示时间点（09:30）昨天显示：昨天； 超过2天且在本年内 显示*月*日；超过2天且不在本年内 显示*年*月*日
 */
export const formatTime = timeStamp => {
  const currentTimeStamp = Date.now()
  const differenceSecond = parseInt((currentTimeStamp - timeStamp) / 1000)
  if (differenceSecond < 0) return moment(timeStamp, 'YYYY年MM月DD日')
  else if (differenceSecond < 60) return '刚刚'
  else if (differenceSecond < 60 * 10) return parseInt(differenceSecond / 60) + '分钟前'
  else {
    const time = new Date(timeStamp)
    const currentTime = new Date(currentTimeStamp)
    // 时间差计算存在隔天与否的分歧，统一使用年月日比较显示---产品已确认
    if (time.getFullYear() === currentTime.getFullYear()) {
      const sameMonth = currentTime.getMonth() === time.getMonth()
      const differenceDate = currentTime.getDate() - time.getDate()
      if (sameMonth && differenceDate < 2) {
        return differenceDate === 0 ? moment(timeStamp, 'HH:mm') : '昨天'
      }
      return moment(timeStamp, 'MM月DD日')
    }
    return moment(timeStamp, 'YYYY年MM月DD日')
  }
}

/**
 * 活动倒计时文案显示
 * @param {Number} count 要转换的倒计时秒数
 * @param {Boolean} endDay 是否只要截止到天
 * @param {Boolean} resObj 要返回对象类型{day, hour, minute, second}
 * @return xx天23:59:59 / {day, hour, minute, second}
 */
export const getCountText = (count, endDay = false, resObj = false) => {
  if (!count || count < 1) return ''
  const getText = (num, adRate) => {
    const multiple = Math.floor(num / adRate)
    return {
      text: multiple > 9 ? multiple : `0${multiple}`,
      remainder: Math.floor(num - (multiple * adRate).toFixed(0))
    }
  }
  let { text: day, remainder: hourRemainder } = getText(count, 86400)
  if (!resObj) {
    day = day > 0 ? `${day}天` : ''
    if (endDay && day) return day
  }
  const { text: hour, remainder: minuteRemainder } = getText(hourRemainder, 3600)
  let { text: minute, remainder: secondRemainder } = getText(minuteRemainder, 60)
  secondRemainder = secondRemainder > 9 ? secondRemainder : `0${secondRemainder}`

  return resObj
    ? { day, hour, minute, second: secondRemainder }
    : `${day}${hour}:${minute}:${secondRemainder}`
}

/**
 * 数字位数统一保留函数
 * @param {Number} val 要转换的数字
 * @param {Number} length 最大要保留的小数位数（未超过则不做处理），默认为2
 * @return 对应格式的数字
 */
export const decimalLimit = (val, length = 2) => {
  const numArr = (val + '').split('.')
  const decimal = numArr[1]
  if (decimal && decimal.length > length) {
    numArr[1] = (decimal / Math.pow(10, decimal.length - length)).toFixed(0) + ''
    if (numArr[1].length < length) numArr[1] = numArr[1].padStart(length, '0')
  }
  return !numArr[1] || numArr[1] > 0
    ? Number(numArr.join('.'))
    : Number(numArr.join('.')).toFixed(length) // 处理小数为00的情况
}

/**
 * 检测类型是否正确
 * 'bigint' 'symbol' 'boolean' 'number' 'string' 'function' 'undefined' 'null-object' 'object-object' 'array-object'
 * @param {*} checkVar
 * @param {*} checkType
 * @returns Boolean
 */
export const checkVarType = (checkVar, checkType) => {
  if (typeof checkVar === 'object') {
    if (checkType === 'object') return checkVar instanceof Object
    if (checkType === 'array') return checkVar instanceof Array
    if (checkType === 'null') return checkVar === null
  } else return typeof checkVar === checkType
}

// 金额转换10000=>￥10，000,
// type: 1,有人民币标识，2，没有人民币标识
// decimal 小数点位数（默认2位）

/**
 * 金额转换10000=>￥10，000,
 * @param {*} money 金额
 * @param {*} type 1,有人民币标识，2，没有人民币标识
 * @param {*} decimal 小数点位数（默认2位）
 * @returns 转换后的金额
 */
export const setMoney = (money, type = 1, decimal = 2) => {
  let data = null
  money = money === null ? null : Number(money)
  if (typeof money === 'number' && !isNaN(money)) {
    const Big = require('big.js')
    money = Big(money).toFixed(decimal)
    data = `${money}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (type === 1) data = `￥${data}`
  } else data = '--'
  return data
}

/**
 * 高德地图省市区地址名称转换
 * @param {String} province 省
 * @param {String} city 市
 * @param {String} district 区
 * @return { province, city, district }
 */
export const addressTransform = (province, city, district) => {
  if (['北京市', '上海市', '天津市', '重庆市'].includes(province)) city = '市辖区'
  if (province === '重庆市' && /县$/.test(district)) city = '县'
  if (['香港特别行政区', '澳门特别行政区'].includes(province)) {
    city = district
    district = undefined
  }
  return { province, city, district }
}
/**
 * 高德地图省市区地址名称转换后获取城市名-配套
 * @param {Object} data {province, city, district}
 * @return name 城市名
 */
export const getCityCn = data => {
  let { province, city = '' } = data
  if (['北京市', '上海市', '天津市', '重庆市'].includes(province)) city = province
  return city.replace(/(市|地区|特别行政区|自治州|级行政区划)/, '')
}

/**
 * 高德地图获取省市区地址信息+对应后端数据库的cityCode
 * @param {Boolean} isUseDefault 当获取失败时是否使用默认值（厦门）
 * @return {Object} {province, city, district,cityCode}
 */
export const getMapAsync = async (isUseDefault = true) => {
  let info = { province: '福建省', city: '厦门市', district: '集美区', cityCode: '350200' }
  // 在app内部时使用app定位, toAppGetLocation返回：lat，lng，province，city，district,，address，isAuth（0，未授权；1，已授权）
  if (Bus.isApp) {
    const appRes = await NativeAPI.callNativeApi('toAppGetLocation')
    const isAuth = appRes && appRes.isAuth === 1
    if (!isAuth || !appRes.province) return isUseDefault ? info : {} // 用户未开启定位授权则不进行获取定位信息--是否开启了定位授权-isAuth（0，未授权；1，已授权）

    // 高德特殊省市区转换
    let address = addressTransform(appRes.province, appRes.city, appRes.district)
    // 获取当前定位城市cityCode
    const cityRes = await Request.getCityCode({ province: address.province, city: address.city })
    address.cityCode = cityRes.code === 0 ? cityRes.data.cityCode : ''
    return address
  }
  // 执行获取定位信息
  try {
    const AMapLoader = require('@amap/amap-jsapi-loader')
    const res = await AMapLoader.load({
      key: 'd1dd6d7aaf631ce4a1bcdc4f24fb42e2',
      version: '2.0',
      plugins: ['AMap.Geolocation', 'AMap.PlaceSearch']
    })
    // 获取当前定位省市区信息
    await new Promise(resolve => {
      const geolocation = new res.Geolocation({ timeout: 10000, zoomToAccuracy: true })
      console.log('*******高德定位*******', geolocation)
      geolocation.getCityInfo(async (status, result) => {
        console.log('*******获取当前城市信息*******', status, result)
        if (status === 'complete') {
          const address = addressTransform(result.province, result.city, result.district)
          info = Object.assign(info, address)
          info.adcode = result.adcode || undefined
          info.position = result.position || undefined
        } else if (!isUseDefault) info = {}
        resolve()
      })
    })
    // 获取当前定位城市cityCode
    const cityRes = await Request.getCityCode({ province: info.province, city: info.city })
    if (cityRes.code === 0) info.cityCode = cityRes.data.cityCode
  } catch (err) {
    console.log(err)
    if (!isUseDefault) info = {}
  }
  return info
}

/**
 * 高德地图获取当前位置经纬度
 * @return {Object} {lat, lng}
 */
export const getMapPositionAsync = async () => {
  let info = { lat: 24.597744, lng: 118.004006 }
  // 在app内部时，用户未开启定位授权则不进行获取定位信息--是否开启了定位授权-isAuth（0，未授权；1，已授权）
  if (Bus.isApp) {
    const appRes = await NativeAPI.callNativeApi('toAppGetLocation')
    const isAuth = appRes && appRes.isAuth === 1
    return isAuth
      ? { lat: appRes.lat || info.lat, lng: appRes.lng || info.lng, isAuth: true } // 增加是否isAuth开启了定位授权
      : info
  }
  // 执行获取定位信息
  try {
    const AMapLoader = require('@amap/amap-jsapi-loader')
    const res = await AMapLoader.load({
      key: 'd1dd6d7aaf631ce4a1bcdc4f24fb42e2',
      version: '2.0',
      plugins: ['AMap.Geolocation', 'AMap.PlaceSearch']
    })
    // 获取当前定位经纬度
    await new Promise(resolve => {
      const geolocation = new res.Geolocation({ timeout: 10000, zoomToAccuracy: true })
      console.log('*******高德定位*******', geolocation)
      geolocation.getCurrentPosition(async (status, result) => {
        console.log('*******获取当前定位经纬度*******', status, result)
        if (status === 'complete') {
          info = {
            lat: result.position.lat,
            lng: result.position.lng,
            isAuth: true // 增加是否isAuth开启了定位授权
          }
        }
        resolve()
      })
    })
  } catch (err) {
    console.log(err)
  }
  return info
}

// 商品销量格式化---1W内显示为实际值，[10000,20000)显示为1万+，[20000,30000)显示为2万+，[100000，+∞）显示为10万+
/**
 * 商品销量格式化
 * @param {Number, String} val 销售量
 * @return {String}
 */
export const salesTransform = val => {
  if (val < 10000) {
    return val
  } else if (val < 100000) {
    const Big = require('big.js')
    const num = Big(val).div(10000).round(0, Big.roundDown).toFixed(0)
    return `${num}万+`
  } else if (val >= 100000) {
    return '10万+'
  }
  return val
}

/**
 * 金额格式化 // 金额拆解-resType：int返回整数 decimal返回小数
 * @param {Number, String} price 金额
 * * @param {Number, String} resType int返回整数 decimal返回小数
 * @return {String}
 */
export const priceFormat = (price, resType = 'int') => {
  let bigPrice = Big(price)
  if (bigPrice > 10000) bigPrice = bigPrice.div(10000)
  bigPrice = bigPrice.round(2, Big.roundDown).toFixed(2)

  let [int, decimal] = bigPrice.split('.')
  decimal = `.${decimal}${price > 10000 ? '万' : ''}`

  return resType === 'int' ? int : decimal
}

export const linkJump = item => {
  const urlType = Number(item.urlType)
  // 售后链接需要登录后才可以跳转
  if ([3].includes(urlType) && !store.getters.isLogin) return Login()
  if ([0, 1, 3].includes(urlType)) {
    const { token, appVersion = '', navigationBarHeight = 0 } = store.state.user
    const url = [3].includes(urlType)
      ? `${item.url}?sid=${token}&souce=4&v=${appVersion}&h=${navigationBarHeight}`
      : item.url
    // 获取当前页面url的源
    const currentOrigin = window.location?.origin
    // 如果跳转的地址开头跟当前页面源一致,就清掉url中的域名,用router方法跳转
    if (url.indexOf(currentOrigin) === 0) router.push(url.replace(currentOrigin, ''))
    else window.open(url, '_blank')
  }
}

/**
 * 版本比较（版本号格式：'主版本号.次版本号.修订版本号'）
 * @author chenrenbin
 * @param {String} v1 版本号1
 * @param {String} v2 版本号2
 * @return {Number} 比较结果--（-1：小于；0：等于; 1：大于）
 */
export const compareVersion = (v1, v2) => {
  v1 = v1.split('.')
  v2 = v2.split('.')
  const len = Math.max(v1.length, v2.length)

  while (v1.length < len) v1.push('0')
  while (v2.length < len) v2.push('0')

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i])
    const num2 = parseInt(v2[i])
    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  }
  return 0
}

/**
 * 判断传入版本是否兼容, 传入的是最后一个旧版本（版本号格式：'主版本号.次版本号.修订版本号'）
 * @param {String} androidVer 安卓最小版本
 * @param {String} iosVer IOS最小版本
 * @return {Number} 当前版本满足版本限制(大于最后一个旧版本)
 */
export const versionHasAuth = (androidVer, iosVer) => {
  const currVer = store.state.user.appVersion
  // 判断系统,取不同的版本字段
  const version = Bus.isiOS ? iosVer : androidVer
  return compareVersion(currVer, version) >= 1
}

/**
 * @description: 随机生成唯一标识
 * @return {String}
 */
let UUID = 0
export const uniqueID = () => {
  return 'UUID' + new Date().getTime() + (Math.random() + UUID++).toString(36).replace('.', '')
}

/**
 * @description: 好礼共享-发起助力, 获取分享相关信息
 * @param {String} activityId 活动id
 * @param {String} activityUseId 助力记录id
 * @return {Promise} 获取助力成功参数后, 调用app方法
 */
export const lauchShare = async data => {
  // 重复发起助力若有助力之中的会返回助力中的activityUseId---存在停留助力页面活动删除编辑等情况，助力记录id等信息都通过助力接口获取
  const res = await Request.launchActivity({
    activityId: data.activityId,
    activityUseId: data.activityUseId || ''
  })
  if (res.code === 0) {
    // 助力状态res.data.status 0：成功，1：今日发起次数已达上限，2：发起次数已达上限 3: 当前助力已结束
    if (res.data.status === 0) {
      const { activityId, activityUseId, shareExtensionTitle, shareExtensionImg } = res.data
      const params = {
        path: `pages/my/subPages/activity/help?activityId=${activityId}&activityUseId=${activityUseId}`,
        // activityId,
        // activityUseId,
        title: shareExtensionTitle,
        thumbImg: shareExtensionImg
      }
      return params
    } else {
      return { ...res.data, msg: res.msg }
    }
  } else {
    return res
  }
}

/**
 * 通过枚举值获取字段名
 * @param {*} value
 * @param {*} data
 * @returns label
 */
export const getLabel = (data, value) => {
  const item = data.find(item => item.value === value)
  return item?.label || ''
}

/**
 * 向路径补充指定参数
 * @param {*} uri
 * @param {*} key
 * @param {*} value
 * @returns {*} 格式化后的结果
 */
const setQuery = (uri, key, value) => {
  if (!value) return uri

  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return uri + separator + key + '=' + value
  }
}

/**
 * H5分享小程序
 * @param {*} params
 * @param {*} data
 * @returns {Promise} 调用后执行自定义内容
 */
export const handWxShare = params => {
  // 处理传入的path添加分销码
  const path = setQuery(params.path, 'distribution', store.state.user?.distributionId)
  if (Bus.isApp) {
    console.log('分享小程序到微信:', Object.assign({}, params, { path }))
    NativeAPI.callNativeApi('toAppShareWechat', Object.assign({}, params, { path })).then(() => {
      Promise.resolve()
    })
  }
}

/**
 * RGB转换为16进制
 * @param {*} rgb
 * @param {*} hex
 * @returns label
 */
export const colorRgbToHex = rgbStr => {
  //十六进制颜色值的正则表达式
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6}|[0-9a-fA-f]{8}|[0-9a-fA-f]{6}[0-9]{2})$/
  if (reg.test(rgbStr)) {
    return rgbStr
  } else {
    const rgbArray = rgbStr.replace(/(?:\(|\)|rgba|rgb|RGBA|RGB)*/g, '').split(',')
    let strHex = '#'
    for (let i = 0; i < rgbArray.length; i++) {
      if (i !== 3) {
        if (rgbArray[i] === '0') {
          strHex += '00'
        } else {
          let newItem = Number(rgbArray[i]).toString(16)
          if (newItem.length < 2) {
            newItem = '0' + newItem
          }
          strHex += newItem
        }
      } else {
        strHex += rgbArray[i] === '0' ? '' : Number(rgbArray[i]) * 100
      }
    }
    return strHex
  }
}

// 在列表中,根据value寻找label
/**
 * list:要寻找的数据源数组
 * val:根据val的值来寻找
 * value:数据中value的key
 * label:数据中label的key
 * mode:找寻的模式
 *      {
 *        label:寻找val对应的label
 *        item:寻找val对应的item整个项
 *        index:寻找val对应的index索引
 *        all:寻找item整个项和索引
 *      }
 */
export const findLabelByValue = (list, val, value = 'value', label = 'label', mode = 'label') => {
  if (!list?.length) return
  let back
  for (let i = 0, l = list.length; i < l; i++) {
    let e = list[i]
    if (val === e[value]) {
      back = {
        label: e[label],
        item: e,
        index: i
      }
      break
    }
  }
  return mode === 'all' ? back : back[mode]
}

/**
 * 服务费计算
 * @param {*} item  商品项
 * @param {*} servicePriceRules 服务费规则系数
 * @returns serviceFee 服务费
 */
export const serviceFeeCacl = (item = {}, servicePriceRules) => {
  let serviceFee = 0 // 服务费

  // initialPrice 发行价 -> 标准零售价(立林) 销售价(生态) 累加价格(组合产品<标准零售价+销售价>)
  // 1.7.6 固定比例:
  // 立林: 服务费 = 单价priceLeelenInCombine(标准零售价) /系数 x 百分比 x 数量
  // 生态: 服务费 = 单价priceEcolInCombine(销售价) x 服务百分比 x 数量

  // priceLeelenInCombine 立林
  // priceEcolInCombine 生态
  let { priceLeelenInCombine = 0, priceEcolInCombine = 0, serviceType, serviceFeeRatio } = item

  if (!serviceFeeRatio) serviceFeeRatio = 0
  if (!priceLeelenInCombine) priceLeelenInCombine = 0
  if (!priceEcolInCombine) priceEcolInCombine = 0
  if (serviceType === 1) {
    // 固定比例
    const leelenServiceFee = Big(priceLeelenInCombine)
      .div(servicePriceRules)
      .times(serviceFeeRatio)
      .toFixed(4) // 为了与后端保持一致 此处保留四位小数

    const ecolInServiceFee = Big(priceEcolInCombine).times(serviceFeeRatio)
    serviceFee = +Big(leelenServiceFee).add(ecolInServiceFee).toFixed(2)
  } else if (serviceType === 0) {
    // 固定价格
    serviceFee = +Big(serviceFeeRatio).toFixed(2)
  }

  return { serviceFee }
}
