import clonedeep from 'lodash.clonedeep'
import Request from '_api'

const STATE = {
  selectProduct: {}, // 选择宝贝
  selectAfterSalesType: {}, //选择售后类型
  servicePriceRules: 1 // 服务费规则系数
}

const order = {
  state: clonedeep(STATE),
  mutations: {
    SET_SELECT_PRODUCT(state, data = {}) {
      state.selectProduct = data
    },
    SET_SELECT_AFTER_SALES_TYPE(state, data = {}) {
      state.selectAfterSalesType = data
    },
    SET_SERVICE_RULES(state, data) {
      state.servicePriceRules = data || 1
    },
    // 重置state
    RESET(state) {
      Object.assign(state, clonedeep(STATE))
    }
  },
  actions: {
    // 获取服务费规则系数
    async GetServiceRules({ commit }) {
      const res = await Request.getServiceRules()
      if (res.code === 0) {
        commit('SET_SERVICE_RULES', res.data)
      }
    }
  }
}

export default order
