const requireModules = require.context('./', false, /^\.\/((?!index).)*\.js$/)
// 路由对象
const routeList = [
  { type: 'home' },
  { type: 'sort' },
  { type: 'star' },
  { type: 'cart' },
  { type: 'my' },
  { type: 'other' }
]
// 路由分类，分类即为tab类型
// 如果父级路由需要登录，那么所有的子级路由也必须登录
// 为父级路由获取重定向地址
const setRouteType = (routes, type, needLogin = false) => {
  routes.forEach(route => {
    route.meta.type = type
    if (needLogin) route.meta.needLogin = needLogin
    if (route.children) {
      route.redirect = route.children[0].path
      setRouteType(route.children, type, route.meta.needLogin)
    }
  })
}
// 路由列表
let routes = []
routeList.forEach(item => {
  requireModules.keys().forEach(path => {
    const moduleName = path.replace(/^\.\/(.*)\.js$/, '$1')
    if (item.type === moduleName) {
      item.router = requireModules(path).default
      if (moduleName !== 'other') {
        setRouteType(item.router, moduleName)
        item.title = item.router[0].meta.title
        item.path = item.router[0].path
      }
      routes = routes.concat(item.router)
    }
  })
})

export const routeObj = routeList
export default routes
