import emitter from 'tiny-emitter/instance'
import MobileDetect from 'mobile-detect'

const UA = window.navigator.userAgent.toLowerCase()
const md = new MobileDetect(UA)
const platform = window.navigator.platform.toLowerCase()
const isRealAndroid = /(linux armv8l|linux armv7l|linux aarch64)/i.test(platform)
const isRealiOS = /(iphone|ipad|ipod|ios)/i.test(platform)
const isRealMobile = isRealAndroid || isRealiOS

const bus = {
  style: {
    primaryColor: '#fab400'
  },
  UA,
  platform,
  // 是否是在app中
  isApp: /leelenapp/i.test(UA),
  // 是否是在运营平台app中
  isCRM: /leelenappcrm/i.test(UA),
  // 是否是在乐比邻app中
  isLBL: /leelenapplbl/i.test(UA),
  // 手机型号
  model: md.mobile(),
  // 是否是真的移动端
  isRealMobile,
  // 是否至少是模拟移动端
  isMobile: isRealMobile || !!md.mobile(),
  // 是否是真实安卓移动端
  isRealAndroid,
  // 是否是32安卓移动端位
  isAndroid32: /linux armv7l/i.test(platform),
  // 是否至少是模拟安卓移动端
  isAndroid: isRealAndroid || md.os() === 'AndroidOS',
  // 是否是真实苹果移动端
  isRealiOS,
  // 是否至少是模拟苹果移动端
  isiOS: isRealiOS || md.os() === 'iOS',
  // 系统版本
  osVersion: md.version('iPhone') || md.version('Android'),
  // 是否是微信浏览器
  isWeixin: /micromessenger/i.test(UA),
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}

console.log('系统信息', bus)

export default bus
