import '_u/vconsole'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Bus from '_u/bus'
import Big from 'big.js'
import { getMapAsync } from '_u/tool'
import EventTrack from './utils/eventTrack'

import { Button, Col, Row, Icon, Image, Badge } from 'vant'
import { Cell, CellGroup, Popup, Form, Field, Toast, Dialog } from 'vant'
import { Tag, Tab, Tabs, Swipe, SwipeItem } from 'vant'
Toast.setDefaultOptions({ duration: 3000 })
Toast.setDefaultOptions('loading', { forbidClick: true })

const app = createApp(App)

// 新的高德地图key需要配合您的安全密钥一起使用
window._AMapSecurityConfig = {
  securityJsCode: '5b37211fb6709e89577730f7dd7e918d'
}

getMapAsync().then(cityInfo => {
  app.use(store).use(router)

  app.use(Button).use(Col).use(Row).use(Icon).use(Image).use(Badge)
  app.use(Cell).use(CellGroup).use(Popup).use(Form).use(Field).use(Dialog)
  app.use(Tag).use(Tab).use(Tabs).use(Swipe).use(SwipeItem)

  app.config.globalProperties.$bus = Bus
  app.config.globalProperties.Big = Big
  app.config.globalProperties.EventTrack = EventTrack
  window.Big = Big
  window.EventTrack = EventTrack

  store.commit('SET_LOCATIONADDR', cityInfo)
  app.mount('#app')
})

export default app
