import clonedeep from 'lodash.clonedeep'
import Bus from '_u/bus'
import NativeAPI from '_u/nativeAPI'
import Request from '_api'

const STATE = {
  token: null,
  appToken: null,
  appVersion: null,
  userInfo: null, // 用户信息
  addressList: [], // 用户地址列表
  useOperationAddr: '', // 是否使用用户操作的收货地址（为空时使用接口根据城市过的地址），当前固定值createOrder(创建订单页) returnOrder(申请售后页)
  operationAddrInfo: {}, // 用户下单时新增（无地址时）、地址列表选择的收货地址信息
  orderSnapshot: {}, // 填写订单页快照，用于切换地址前端对比商品变更信息（商品后台会实时变更所以存在同城市地址也会有变更）
  navigationBarHeight: 0, // APP导航栏高度
  distributionId: null, // 分销id
  distributionInfo: '' // 用户分销信息
}

const user = {
  state: clonedeep(STATE),
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_APPTOKEN(state, token) {
      state.appToken = token
    },
    SET_APPVERSION(state, version) {
      state.appVersion = version
    },
    SET_APPBARHEIGHT(state, height) {
      if (height !== state.navigationBarHeight) {
        const doc = document.documentElement
        doc.style.setProperty('--app-nav-bar-height', `${height}px`)
      }
      state.navigationBarHeight = height
    },
    SET_USERINFO(state, info) {
      info.originalPhone = info.phone
      info.phone = info.phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
      state.userInfo = info
    },
    // 存储地址列表
    SET_ADDRESS(state, list) {
      state.addressList = list
    },
    SET_USEOPERATIONADDR(state, payload) {
      state.useOperationAddr = payload
    },
    SET_OPERATIONADDRINFO(state, payload) {
      state.operationAddrInfo = payload
    },
    SET_ORDERSNAPSHOT(state, payload) {
      state.orderSnapshot = payload
    },
    // 存储分销信息
    SET_DISTRIBUTITON_USERINFO(state, info) {
      state.distributionId = info?.id
      state.distributionInfo = info
    },
    // 重置state
    RESET(state) {
      Object.assign(state, clonedeep(STATE))
    }
  },
  getters: {
    // 登录状态
    isLogin(state) {
      return !!(state.userInfo && state.userInfo.phone)
    }
  },
  actions: {
    async AppLogin({ commit }) {
      let token
      if (Bus.isApp) {
        const appres = await NativeAPI.callNativeApi('toAppGetToken')
        token = appres.token
        commit('SET_APPBARHEIGHT', appres.navigationBarHeight || 0)
        commit('SET_APPVERSION', appres.version || null)
      } else if (process.env.NODE_ENV === 'development') {
        // 开发使用token
        // token = '23912334735644faa3c4e61a09132bac' // 201
        // token = '11c9de281bce49d19c9274d995793442' // 56
        token = '12de6a422434458b9a3a0af93f480849' // dev3
      }
      commit('SET_APPTOKEN', token)
      const res = await Request.appLogin()
      if (res.code === 0) {
        commit('SET_TOKEN', res.data.sid)
        commit('SET_USERINFO', res.data)
        // 存放分销信息
        await this.dispatch('GetDistributionInfo')
      }
      return Promise.resolve()
    },
    // 获取分销信息
    async GetDistributionInfo({ commit, state }) {
      const res = await Request.getDistributionUserInfo()
      if (res.code === 0) {
        // 将分销信息存在全局
        // commit('SET_DISTRIBUTION_ID', res.data?.id)
        // 单独处理头像与昵称，为了解决app端修改后，小程序能及时响应更新
        const userInfo = Object.assign({}, state.userInfo, {
          nickname: res.data?.name,
          avatar: res.data?.headImage,
          phone: state.userInfo.originalPhone // 传入的phone为源手机号
        })
        commit('SET_USERINFO', userInfo) // 更新用户头像与昵称
        commit('SET_DISTRIBUTITON_USERINFO', res.data) // 存用户分销信息
      }
    },
    // 获取收货地址
    async GetAddressList({ commit }) {
      const res = await Request.listReceiverInfo()
      if (res.code === 0) commit('SET_ADDRESS', res.data)
      return Promise.resolve()
    }
  }
}

export default user
