import BaseLayout from '@/layouts/BaseLayout'

export default [
  {
    name: 'homeTab',
    path: '/',
    alias: '/index.html',
    component: BaseLayout,
    meta: {
      title: '首页'
    },
    children: [
      {
        name: 'home',
        path: '/',
        component: () => import('_p/home'),
        meta: {
          title: '首页',
          isTransparent: true
          // headerColor: true
        }
      },
      {
        name: 'search',
        path: '/home/search',
        component: () => import('_p/home/search'),
        meta: {
          title: '搜索'
        }
      },
      {
        name: 'searchResult',
        path: '/home/search/result',
        component: () => import('_p/home/search/result'),
        meta: {
          trackTitle: '搜索结果',
          title: '搜索'
        }
      },
      {
        name: 'goodDetail',
        path: '/home/product/detail/:id',
        component: () => import('_p/home/product/detail'),
        meta: {
          title: '商品详情',
          nofooter: true
        }
      },
      {
        name: 'goodContrastParams',
        path: 'pages/home/product/contrastParams',
        component: () => import('_p/home/product/contrastParams'),
        meta: {
          title: '参数对比',
          nofooter: true
        }
      },
      {
        name: 'discount',
        path: '/home/discount/:id',
        component: () => import('_p/home/discount'),
        meta: {
          title: '折扣专区'
        }
      },
      {
        name: 'activityArea',
        path: '/home/activityArea/:id',
        component: () => import('_p/home/activityArea'),
        meta: {
          title: '活动专区'
        }
      },
      {
        name: 'brand',
        path: '/home/brand/:id',
        component: () => import('_p/home/brand'),
        meta: {
          title: '品牌墙',
          headerColor: true
        }
      },
      {
        name: 'brand',
        path: '/home/brand/detail/:id',
        component: () => import('_p/home/brand/Detail'),
        meta: {
          title: '详情页'
        }
      },
      {
        name: 'kong',
        path: '/home/kong/:id',
        component: () => import('_p/home/kong'),
        meta: {
          title: '',
          headerColor: true
        }
      },
      // {
      //   name: 'suit',
      //   path: '/home/suit/:type',
      //   component: () => import('_p/home/suit'),
      //   meta: {
      //     titleKey: 'type',
      //     title: { combination: '组合套装', housetype: '户型套装' }
      //   }
      // },
      {
        name: 'advertDetail',
        path: '/home/advert/detail/:id',
        component: () => import('_p/home/advert'),
        meta: {
          trackTitle: '活动详情',
          title: '详情页'
        }
      },
      {
        name: 'discuss',
        path: '/home/discuss/:id/:rate',
        component: () => import('_p/home/discuss'),
        meta: {
          trackTitle: '评价详情',
          title: '评价'
        }
      },
      {
        name: 'guid',
        path: '/home/guid/:id',
        component: () => import('_p/home/guid'),
        meta: {
          title: '精选指南'
        }
      },
      {
        name: 'homeCoupon',
        path: '/home/coupon',
        component: () => import('_p/home/coupon'),
        meta: {
          title: '领券中心',
          needLogin: true
        }
      },
      {
        name: 'switchLocation',
        path: '/home/switchLocation',
        component: () => import('_p/home/switchLocation'),
        meta: {
          title: '选择城市'
        }
      },
      {
        name: 'procurement',
        path: '/home/procurement',
        component: () => import('_p/home/procurement'),
        meta: {
          title: '企业采购'
        }
      },
      {
        name: 'nearbyStores',
        path: '/home/nearbyStores',
        component: () => import('_p/home/nearbyStores'),
        meta: {
          title: '附近门店'
        }
      },
      {
        name: 'moreNewProduct',
        path: '/home/moreNewProduct/:id/:title?',
        component: () => import('_p/home/moreNewProduct'),
        beforeEnter(to, from, next) {
          to.meta.title = to.params?.title || '新品推荐'
          next()
        },
        meta: {
          title: '新品推荐'
        }
      }
    ]
  }
]
