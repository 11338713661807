import axios from 'axios'
import { apiConfig } from '@/config'
import Qs from 'qs'
import store from '@/store'

class Http {
  config() {
    // 基础配置
    const config = {
      timeout: 10000,
      headers: {
        ...apiConfig,
        sid: store.state.user.token || '',
        Authorization: store.state.user.appToken ? `Bearer ${store.state.user.appToken}` : '',
        cityCode: store.state.app.locationAddr.cityCode || '',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    return config
  }

  interceptors(instance) {
    // 请求拦截
    instance.interceptors.request.use(
      request => {
        // 配置请求参数
        let requestData = request.parameters || {}
        // 上传文件时，对象处理
        if (request.headers['Content-Type'] === 'multipart/form-data') {
          requestData = requestData.formData
        }
        if (request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
          requestData = Qs.stringify(requestData, {
            allowDots: true,
            arrayFormat: 'repeat'
          })
          if (request.method === 'get') {
            request.url = `${request.url}${requestData ? '?' : ''}${requestData}`
          } else request.data = requestData
        } else {
          if (request.method === 'get') request.params = requestData
          else request.data = requestData
        }
        return request
      },
      error => {
        return Promise.reject(error)
      }
    )
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        // 登录已失效则退出
        if (res.data.code === 2301) {
          store.commit('RESET')
          setTimeout(() => {
            location.replace('/')
          }, 3000)
        }
        return { traceId: res.headers.traceid, ...res.data }
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  request(options) {
    // 实例化
    const instance = axios.create()
    // 合并配置
    const config = this.config()
    const headers = Object.assign({}, config.headers, options.headers)
    options = Object.assign(config, options)
    options.headers = headers

    // 拦截器
    this.interceptors(instance)
    return instance(options)
  }
}

export default Http
