export default {
  getWalletInfo: {
    title: '获取用户余额',
    url: '/api/shop/wallet/info'
  },
  getWalletRecord: {
    title: '我的钱包 - 流水分页查询',
    url: '/api/shop/wallet/record/list'
  },
  getWalletRule: {
    title: '获取赠送金规则',
    url: '/api/shop/wallet/ruleInfo'
  }
}
