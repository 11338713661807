export default {
  getHomePageData: {
    title: '获取首页数据',
    url: '/api/shop/applets/homePage'
  },
  getHotSale: {
    title: '获取热卖单品',
    url: '/api/shop/applets/listHotSaleGoods'
  },
  advertiseOrActivityDetail: {
    title: '广告/活动-详情',
    url: '/api/shop/applets/advertiseOrActivityDetail'
  },
  getGuideClassificationInfo: {
    title: '精选指南内页 - 分类基本信息',
    url: '/api/shop/applets/getSelectedGuideClassificationInfo'
  },
  getGuidGoods: {
    title: '精选指南内页 - 获取商品',
    url: '/api/shop/applets/listSimpleGoodsInfoByClassification'
  },
  getBrandWallBaseInfo: {
    title: '品牌墙内页-基本信息',
    url: '/api/shop/applets/getBrandWallBaseInfo'
  },
  getBrandCustomizedContent: {
    title: '获取品牌自定义的内容',
    url: '/api/shop/applets/getBrandCustomizedContent'
  },
  getBrandKinds: {
    title: '品牌墙内页-获取多级分类列表',
    url: '/api/shop/applets/listBrandMultiClassificationInfo'
  },
  getBrandGoods: {
    title: '品牌墙内页-获取商品',
    url: '/api/shop/applets/listGoodsForBrandWall'
  },
  getCity: {
    title: '获取城市定位地址',
    url: '/api/shop/position/city'
  },
  getDiscountGoods: {
    title: '获取折扣商品',
    url: '/api/shop/featuredFirst/listFeaturedFirstGoods'
  },
  getActivityAreaItemList: {
    title: '活动专区内页列表',
    url: '/api/shop/area/getActivityAreaItemList'
  },
  getWelfareAreaList: {
    title: '小程序/h5-福利专区',
    url: '/api/shop/applets/getWelfareAreaList'
  },
  getSelectedAreaList: {
    title: '小程序/h5-精选专区',
    url: '/api/shop/applets/getSelectedAreaList'
  },
  getContentAreaList: {
    title: '小程序/h5-内容专区',
    url: '/api/shop/applets/getContentAreaList'
  },
  getEnterpriseInfo: {
    title: '小程序/h5-获取企业信息',
    url: '/api/shop/applets/getEnterpriseInfo'
  },
  getNewProductsList: {
    title: '小程序/h5-新品专区',
    url: '/api/shop/applets/getNewProductsList'
  },
  getNewProductsListById: {
    title: '小程序/h5-新品专区-查看更多',
    url: '/api/shop/applets/getNewProductsListById'
  },
  getAdvertisementList: {
    title: '小程序/h5-广告banner轮播',
    url: '/api/shop/applets/getAdvertisementList'
  },
  getBrandWallList: {
    title: '小程序/h5-品牌墙',
    url: '/api/shop/applets/getBrandWallList'
  },
  getWisdomAreaList: {
    title: '小程序/h5-智慧推荐专区',
    url: '/api/shop/applets/getWisdomAreaList'
  },
  getActivityAreaList: {
    title: '小程序/h5-活动专区',
    url: '/api/shop/applets/getActivityAreaList'
  },
  getFeaturedFirsts: {
    title: '小程序/h5-折扣专区',
    url: '/api/shop/applets/getFeaturedFirsts'
  },
  getPromoteList: {
    title: '小程序/h5-宣传位 banner',
    url: '/api/shop/applets/getPromoteList'
  },
  insertBusinessBuy: {
    title: '企业采购',
    url: '/api/operation/applets/insert',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getStoreList: {
    title: '企业列表',
    url: '/api/shop/store/list',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  storeRecommended: {
    title: '推荐门店',
    url: '/api/shop/store/recommended',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getKongAreaList: {
    title: '小程序/h5-金刚区',
    url: '/api/shop/applets/getNavigationList'
  },
  getNavigationBaseInfo: {
    title: '小程序/h5-金刚区-基本信息',
    url: '/api/shop/applets/getNavigationBaseInfo'
  },
  listGoodsForNavigationCategory: {
    title: '小程序/h5-金刚区-分类下商品数据',
    url: '/api/shop/applets/listGoodsForNavigationCategory',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  }
}
