import BaseLayout from '@/layouts/BaseLayout'

export default [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('_p/login'),
  //   meta: {
  //     title: '登录'
  //   }
  // },
  {
    name: 'other',
    path: '/other',
    component: BaseLayout,
    meta: {
      title: '其他'
    },
    children: [
      {
        name: 'other',
        path: '/other/richDetail/:id',
        component: () => import('_p/other/richDetail'),
        meta: {
          trackTitle: '富文本内容通用详情页',
          title: '详情'
        }
      },
      {
        name: 'video',
        path: '/other/video',
        component: () => import('_p/other/video'),
        meta: {
          title: '观看视频'
        }
      }
    ]
  },
  {
    name: 'error',
    path: '/:catchAll(.*)*',
    props: route => route.query,
    component: () => import('_p/error'),
    meta: {
      title: '访问错误'
    }
  }
]
