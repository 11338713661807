export default {
  uploadCommentImg: {
    title: '上传评价图片',
    url: '/api/shop/file/uploadCommentImg',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  getRichContentDetail: {
    title: '自定义富文本内容跳转详情页',
    url: '/api/shop/applets/contentDetail'
  },
  getTheme: {
    title: '获取特殊主题',
    url: '/api/shop/applets/theme'
  },
  getHouseTypeOptions: {
    title: '户型套装字典',
    url: '/api/shop/decorationQuota/dict'
  },
  uploadFile: {
    title: '上传文件',
    url: '/api/shop/file/afs/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
}
