import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'
import routes from './routes'
import store from '@/store'
import Bus from '_u/bus'
import Request from '_api'
import Login from '_c/login'
import setCrmDicRoutes from '_u/serverConfig'
import { pageTrack } from '_u/eventTrack'

// 开发环境中同步路由字典
if (process.env.NODE_ENV === 'development') setCrmDicRoutes()

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      // 刷新页面或进入同一页回到头部
      if (!from.name || from.fullPath === to.fullPath) {
        resolve({ left: 0, top: 0 })
        return
      }
      // 否则回到上次位置
      setTimeout(async () => {
        await nextTick()
        resolve({
          left: savedPosition?.left || 0,
          top: savedPosition?.top || 0
        })
      }, 800)
    })
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  // 初始化登录时验证sid是否过期
  if (!store.state.app.init && store.state.user.token) {
    const res = await Request.getUserInfo()
    if (res.code === 2301) store.commit('SET_TOKEN', null)
    store.commit('SET_INIT')
  }
  // 如果query参数中带有hideHeaderbar=1的参数值，需要在BaseLayout.vue中隐藏页面头部，并修改页面titie内容
  if (to.query?.hideHeaderbar === '1' && to.meta?.title) document.title = to.meta.title
  else document.title = '立林商城'
  // pageFlag 页面标识: App内页跳转进入商城H5页面时传入1,前端根据这个设置返回App操作
  if (to.query?.pageFlag) store.commit('SET_PAGEFLAG', to.query?.pageFlag)

  // 判断是否已经登录
  if (store.state.user.token) next()
  else {
    // 判断是否在app容器中
    if (Bus.isApp || to.query.app) {
      // app去登录
      await store.dispatch('AppLogin')
      // 判断是否需要登录
      if (to.meta.needLogin) {
        // 测试登录是否成功
        if (store.state.user.userInfo) next()
        else next('/error?text=未识别的用户身份')
      } else next()
    } else {
      // h5弹窗登录
      if (to.meta.needLogin) {
        // h5弹窗登录
        Login(to.fullPath)
        // next(new Error())
        next(false)
      } else next()
    }
  }
})

router.afterEach((to, from) => {
  // 进入上报
  pageTrack(1, to)
  // 退出上报
  pageTrack(0, from)
})
router.onError(() => {})

export default router
