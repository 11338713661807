import storage from 'store'
const VERSION = require('../../../package.json').version
const keyName = `LeelenMallH5${VERSION}`

console.log('VERSION:', VERSION)
storage.each(function (value, key) {
  if (key.includes('LeelenMallH5') && key !== keyName) {
    storage.remove(key)
  }
})
export default store => {
  const localStore = storage.get(keyName)
  if (localStore) {
    // 需要移除的状态
    localStore.app.homeTip = false
    localStore.app.init = false
    // 重置加载状态
    store.replaceState(localStore)
  }
  store.subscribe((mutation, state) => {
    storage.set(keyName, state)
  })
}
