export default {
  getFirstLevelGroup: {
    title: '获取一级分组',
    url: '/api/shop/classification/getOneLevelBrandGroup'
  },
  getSecondLevelGroup: {
    title: '获取一级分组下的二级分组',
    url: '/api/shop/classification/getTwoLevelBrandGroup'
  },
  getBrandsUnderGroup: {
    title: '获取二级分组下的品牌列表',
    url: '/api/shop/classification/getBrandsUnderGroup'
  },
  getGoodsUnderGroup: {
    title: '获取二级分组下的热销爆品',
    url: '/api/shop/classification/getDetonationProductsUnderGroup'
  },
  getClassificationList4H5: {
    title: '获取包含上架商品的商品分类：一级分类、二级分类',
    url: '/api/shop/goods/getClassificationList4H5'
  },
  listGoodsForH5: {
    title: '分类-商品分页列表',
    url: '/api/shop/goods/listGoodsForH5',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getOptionQuantity: {
    title: '分类-获取选配单数量',
    url: '/api/shop/optionForm/getOptionQuantity'
  }
}
