export default {
  getMyOptions: {
    title: '获取我的选配单',
    url: '/api/shop/optionForm/listOptionFormItems'
  },
  addOptionList: {
    title: '选配单一键添加',
    method: 'post',
    url: '/api/shop/optionForm/oneClickAdd',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addOption: {
    title: '添加商品到选配单',
    method: 'post',
    url: '/api/shop/optionForm/add',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  replaceOption: {
    title: '替换',
    url: '/api/shop/optionForm/replace',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  updataOrDeleteOption: {
    title: '修改选配单商品数量或删除',
    url: '/api/shop/optionForm/delete',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  updateOptionSelected: {
    title: '选配单更新选中状态',
    url: '/api/shop/optionForm/updateSelected',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addOptionForm: {
    title: '添加选配单到购物车',
    url: '/api/shop/cart/addOptionForm',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getEffectUrl: {
    title: '获取模式效果体验地址',
    url: '/api/shop/optionForm/effectExperience'
  },
  getMoreExperienceMode: {
    title: '更多场景-模式推荐tab栏',
    url: '/api/shop/optionForm/moreExperienceMode'
  },
  getMoreExperienceModeDetail: {
    title: '更多场景-模式下商品列表',
    url: '/api/shop/optionForm/moreExperienceModeDetail'
  },
  emptyOption: {
    title: '选配单-清空',
    method: 'post',
    url: '/api/shop/optionForm/emptyOptionFormItem'
  }
}
