import Http from '_u/request'
import { Toast } from 'vant'
import { lblApiConfig } from '@/config'

const requireModules = require.context('./', true, /^\.\/.+?\/.+?\.js$/)
const apiList = {}
requireModules.keys().forEach(path => {
  const apiObj = requireModules(path).default
  const a = Object.keys(apiObj).length
  const b = Object.keys(apiList).length
  Object.assign(apiList, apiObj)
  const c = Object.keys(apiList).length
  const urlList = Object.values(apiList).map(item => item.url)
  const setUrlList = new Set(urlList)
  if (c !== a + b) throw `${path}中存在重复的api命名`
  if (urlList.length !== setUrlList.size) throw `${path}中存在重复的api地址`
})

const http = new Http()
// 请求等待列队
const queue = {}
// 请求对象
const Request = {}
for (const key in apiList) {
  Request[key] = async (params = {}) => {
    let { apiLoading, apiReject, config = {}, ...parameters } = params
    let res = null
    const url = apiList[key].url

    // 请求等待
    if (apiLoading) {
      queue[url] = Toast.loading({
        message: typeof apiLoading === 'string' ? apiLoading : '加载中...',
        duration: 0
      })
    }
    try {
      // 第三方请求处理
      if (apiList[key].gate === 'lbl') {
        parameters = {
          ...lblApiConfig,
          params: parameters
        }
      }
      res = await http.request({ ...apiList[key], ...config, parameters })
      // 第三方请求处理
      if (apiList[key].gate === 'lbl') {
        res.code = res.result === 1 ? 0 : res.result
        res.data = res.params
        delete res.result
        delete res.params
        delete res.seq
        delete res.waitNum
        delete res.waitTime
      }
    } catch (err) {
      const errObj = {
        'Network Error': '网络不佳',
        'Request failed with status code 404': '服务器开小差了'
      }
      err.message = errObj[err.message] || err.message
      res = err
    } finally {
      // 请求等待结束
      if (queue[url]) {
        queue[url].clear()
        delete queue[url]
      }
    }
    // 请求错误反馈
    if (
      res.code !== 0 &&
      (!apiReject || (typeof apiReject === 'number' && res.code !== apiReject))
    ) {
      Toast.fail(res.message || res.msg)
    }
    if (res.code === 0) console.log(`${apiList[key].title}成功`, res)
    else console.error(`${apiList[key].title}失败`, res, parameters)
    return res
  }
}

export default Request
