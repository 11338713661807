import { createStore } from 'vuex'
import user from './modules/user'
import app from './modules/app'
import order from './modules/order'
import menu from './modules/menu'
import LocalState from './plugin/LocalState'

export default createStore({
  modules: {
    user,
    app,
    order,
    menu
  },
  plugins: [LocalState]
})
