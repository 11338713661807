import packageJson from '_r/package.json'
import store from '@/store'
import Bus from '_u/bus'
import Request from '@/api'

const defaultParams = () => {
  const position = store.state.app.locationAddr

  return {
    phone: store.state.user.userInfo?.originalPhone,
    mtype: Bus.model,
    appletVer: packageJson.version,
    adcode: position.adcode || undefined,
    longitude: position.position?.[0] || undefined,
    latitude: position.position?.[1] || undefined,
    province: position.province || undefined,
    city: position.city || undefined,
    county: position.district || undefined
  }
}

const eventTrack = (eventId, data = {}) => {
  for (const key in data) {
    data[`lee_${key}`] = data[key]
    delete data[key]
  }
  eventId = `lee_${eventId}`
  data = { eventId, paramMap: data, ...defaultParams() }
  Request.trackingEvent({ ...data, apiReject: true })
}

// 页面事件上报
export const pageTrack = (status, route) => {
  // 非app内路由拿不到path不上报
  if (!route.matched.length) return

  const pagePath = route.matched[route.matched.length - 1].path
  const paramMap = route.params
  if (status) route.meta.enterTime = new Date().getTime()
  const enterTime = status ? new Date().getTime() : route.meta.enterTime

  const data = {
    pagePath,
    paramMap,
    status,
    enterTime,
    exitTime: status ? undefined : new Date().getTime(),
    ...defaultParams()
  }
  Request.trackingPage({ ...data, apiReject: true })
}

export default eventTrack
