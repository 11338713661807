/*
 * @Author: tanglinshan
 * @Date: 2023-09-06 14:38:02
 * @LastEditors: tanglinshan
 * @LastEditTime: 2023-09-08 11:11:43
 * @Description: 分销相关接口
 */
export default {
  getDistributionUserInfo: {
    title: '获取分销相关用户信息',
    url: '/api/shop/page/user/info'
  },
  getDistributionBanner: {
    title: '获取分销banner位',
    url: '/api/shop/advertisement/getBannerList'
  },
  getDistributionPosters: {
    title: '获取海报',
    url: '/api/shop/distribution/inviteFriends'
  },
  getDistributionRule: {
    title: '获取规则说明',
    url: '/api/shop/distribution/detail'
  },
  getDistributionOrderList: {
    title: '获取订单列表',
    url: '/api/shop/page/order/list',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getFansList: {
    title: '获取粉丝列表',
    url: '/api/shop/page/fans/list',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getFansDetail: {
    title: '获取粉丝详情',
    url: '/api/shop/page/fans/commissionDetail',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getDistributionOccupation: {
    title: '获取职业列表',
    url: '/api/shop/distribution/occupation'
  },
  submitDistributionAuth: {
    title: '认证提交',
    url: '/api/shop/distribution/submit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getUpdateInfo: {
    title: '推广者认证升级或者编辑修改',
    url: '/api/shop/page/user/update/info'
  },
  withdrawApply: {
    title: '提现申请',
    method: 'post',
    url: '/api/shop/wallet/withdraw-apply',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getWithdrawInfo: {
    title: '提现页面查询',
    method: 'post',
    url: '/api/shop/wallet/get-withdraw-info',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  commissionList: {
    title: '佣金明细列表',
    url: '/api/shop/wallet/commission/list'
  },
  withdrawDetail: {
    title: '提现详情',
    url: '/api/shop/wallet/withdraw/detail'
  },
  commissionAccount: {
    title: '佣金账户信息',
    url: '/api/shop/wallet/commission/account'
  },
  withdrawCheckEmployee: {
    title: '校验是否雇员-H5',
    url: '/api/shop/wallet/withdraw/checkEmployee',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  withdrawDetailReturn: {
    title: '佣金退回详情',
    url: '/api/shop/wallet/withdraw/detailReturn',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  }
}
