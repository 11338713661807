export default {
  getServiceStores: {
    title: '服务门店列表',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/api/shop/store/service/list'
  }
}
