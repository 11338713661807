export default {
  getOrderSelectorList: {
    title: '获取可开票订单',
    url: '/api/shop/invoice/orderSelector'
  },
  saveOrderSelector: {
    title: '保存选中订单',
    url: '/api/shop/invoice/setOrderSelector',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getOrderSelector: {
    title: '获取选中订单',
    url: '/api/shop/invoice/getOrderSelector'
  },
  invoice: {
    title: '开具发票',
    url: '/api/shop/invoice/invoicing',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getInvoiceHistory: {
    title: '开票历史',
    url: '/api/shop/invoice/getInvoiceHis'
  },
  getInvoiceDetail: {
    title: '开票详情',
    url: '/api/shop/invoice/getInvoiceDetail'
  },
  getInvoiceExplain: {
    title: '开票说明',
    url: '/api/shop/invoice/getInvoiceExplain'
  }
}
