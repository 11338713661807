export default {
  getMyCouponsList: {
    title: '我的优惠券列表',
    url: '/api/shop/coupon/listMyCoupons'
  },
  giveCoupon: {
    title: '赠送优惠券',
    url: '/api/shop/coupon/couponGivingSubmit',
    method: 'post',
    timeout: 2800
  },
  getCouponDetail: {
    title: '转赠优惠券详情',
    url: '/api/shop/coupon/getCouponGivingDetail'
  },
  getCouponGiving: {
    title: '转赠优惠券领取',
    url: '/api/shop/coupon/couponGivingGet',
    method: 'post'
  },
  unDoCouonGiving: {
    title: '转赠优惠券撤回',
    url: '/api/shop/coupon/couponGivingRecall',
    method: 'post'
  },
  getCouponsDetail: {
    title: '优惠券详情',
    url: '/api/shop/coupon/detail'
  },
  linkCouponDetail: {
    title: '优惠券详情',
    url: '/api/shop/coupon/couponDetail'
  },
  getOrderCouponList: {
    title: '订单可以使用优惠券列表',
    method: 'post',
    url: '/api/shop/coupon/listOrderCoupon',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getCouponNotice: {
    title: '首页红包提醒',
    url: '/api/shop/coupon/listReminding'
  },
  getCouponList: {
    title: '可领取优惠券列表',
    url: '/api/shop/coupon/listCanBeBroughtCoupons'
  },
  receiveCoupon: {
    title: '领取优惠券',
    url: '/api/shop/coupon/receiveCoupon',
    method: 'post'
  },
  couponCodeConvert: {
    title: '优惠劵码兑换',
    url: '/api/shop/coupon/couponCodeConvert',
    method: 'post'
  },
  couponConvertStatus: {
    title: '优惠劵码兑换状态',
    url: '/api/shop/coupon/couponConvertStatus',
    method: 'post'
  }
}
