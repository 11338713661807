export default {
  getGoodsCategoryList: {
    title: '商品一级类别列表',
    url: '/api/shop/goods/categoryList'
  },
  getGoodsList: {
    title: '商品列表',
    url: '/api/shop/goods/list'
  },
  getGoodsDetail: {
    title: '商品详情信息',
    url: '/api/shop/goods/detail'
  },
  getGoodsCommentList: {
    title: '商品评价信息列表',
    url: '/api/shop/goods/commentList'
  },
  getSearchHistoryList: {
    title: '商品搜索历史列表',
    url: '/api/shop/searchHistory/list'
  },
  clearSearchHistory: {
    title: '清空商品搜索历史',
    method: 'post',
    url: '/api/shop/searchHistory/clear'
  },
  getGoodsRecommendList: {
    title: '为你推荐商品列表',
    url: '/api/shop/goods/recommendList'
  },
  getHouseTypeSuitGoodsList: {
    title: '户型套装内页',
    url: '/api/shop/applets/listHouseTypeSuitGoods'
  },
  getCombinationSuitGoodsList: {
    title: '组合套装内页',
    url: '/api/shop/applets/listCombinationSuitGoods'
  },
  applyCouponList: {
    title: '商品详情页 - 获取适用优惠券列表',
    url: '/api/shop/goods/applyCouponList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  applyCouponTitleList: {
    title: '商品详情页 - 优惠券',
    url: '/api/shop/goods/applyCouponTitleList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  goodsCompare: {
    title: '商品详情-同款比价',
    url: '/api/shop/goods/compare'
  },
  goodsCompareList: {
    title: '商品详情-同款比价-列表',
    url: '/api/shop/goods/compare/list'
  },
  getCompareParam: {
    title: '商品详情-同款比价-参数对比',
    url: '/api/shop/goods/compare/param'
  },
  getGoodsQrCode: {
    title: '获取商品海报小程序码',
    url: '/api/shop/goods/getGoodsQrCode'
  }
}
