import BaseLayout from '@/layouts/BaseLayout'

export default [
  {
    name: 'cartTab',
    path: '/cart',
    component: BaseLayout,
    meta: {
      title: '购物车',
      needLogin: true
    },
    children: [
      {
        name: 'cart',
        path: '/cart',
        component: () => import('_p/cart'),
        meta: {
          title: '购物车',
          nofooter: true
        }
      },
      {
        name: 'selectGift',
        path: '/cart/selectGift',
        component: () => import('_p/cart/selectGift'),
        meta: {
          title: '选择赠品',
          nofooter: true
        }
      }
    ]
  }
]
