export default {
  getDecorationDetail: {
    title: '家装报价详情',
    url: '/api/shop/decorationQuota/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getDecorationQuotaConfig: {
    title: '小程序报价页配置查询',
    url: '/api/shop/decorationQuota/getConfig'
  },
  getQuotaDefaultImgPic: {
    title: '获取家装缺省图',
    url: '/api/shop/decorationQuotaPic/defaultImg'
  },
  saveDecorationQuotaPic: {
    title: '更新客户信息，获取客服信息',
    url: '/api/shop/decorationQuotaPic/save',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  }
}
