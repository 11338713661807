import BaseLayout from '@/layouts/BaseLayout'

export default [
  {
    name: 'sortTab',
    path: '/sort',
    component: BaseLayout,
    meta: {
      title: '分类'
    },
    children: [
      {
        name: 'sort',
        path: '/sort',
        component: () => import('_p/sort'),
        meta: {
          title: '分类',
          headerColor: true
        }
      },
      {
        name: 'option',
        path: '/sort/option/index',
        component: () => import('_p/sort/option/index'),
        meta: {
          title: '选配单'
        }
      },
      {
        name: 'more',
        path: '/sort/option/more',
        component: () => import('_p/sort/option/more'),
        meta: {
          title: '更多场景'
        }
      }
    ]
  }
]
