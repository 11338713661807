export default {
  setCrmDicRoutes: {
    title: '更新crm平台路由字典配置',
    method: 'post',
    url: '/api/system/dict/data/batchInitDictData',
    headers: {
      'Content-Type': 'application/json',
      sid: ''
    }
  },
  trackingPage: {
    title: '页面事件上报',
    method: 'post',
    url: '/api/shop/trackingPage/insert',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  trackingEvent: {
    title: '事件上报',
    method: 'post',
    url: '/api/shop/trackingEvent/insert',
    headers: {
      'Content-Type': 'application/json'
    }
  }
}
