// 手机号
export const matchMobile = /^1\d{10}$/
// 邮箱
export const matchEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
// 固话
export const matchPhone = /^(\d{3,4}-)?(\d{7,14})$/
// 车牌
export const matchCarBoard = /^[0-9a-zA-Z]{5}$/
// 汉字
export const matchWordCn = /^[\u4e00-\u9fa5]+$/
// 密码
export const matchPassword = /^(?=.*[a-zA-Z])(?=.*\d).{6,20}$/
// 金额
export const matchMoney = /^([1-9]\d{0,8}(\.\d{1,2})?|0\.\d{1,2})$/
// url
export const matchUrl =
  /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,64}(?::(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,25})?@)?)?(?:(([-_a-zA-Z0-9]([-_a-zA-Z0-9-]{0,61}[-_a-zA-Z0-9]){0,1}\.)+[a-zA-Z]{2,63}|((25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9]))))(?::\d{1,5})?)(\/(?:(?:[-_a-zA-Z0-9;/?:@&=#~\-.+!*'(),_])|(?:%[a-fA-F0-9]{2}))*)?/
// 整数
export const matchInteger = /^\+?[0-9]*$/
// 邮编
export const matchPostcode = /^[0-9]{6}$/
// 手机号或者固话
export const matchMobileOrPhone = /(^1\d{10}$)|(^(\d{3,4}-)?(\d{7,14})$)/
// 纳税人识别号
export const matchTaxpayerNo = /^[A-Za-z0-9]{15}$|^[A-Za-z0-9]{18}$|^[A-Za-z0-9]{20}$/
//身份证
export const identityCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
//身份证脱敏
export const identityDesensitization = /(^[*]{11}$)|(^\*{14}\d{4}$)|(^\*{14}\d{3}X$)/
