import BaseLayout from '@/layouts/BaseLayout'
import Bus from '_u/bus'
import { sevH5Url } from '@/config'
import store from '@/store'

export default [
  {
    name: 'myTab',
    path: '/my',
    component: BaseLayout,
    meta: {
      title: '我的'
    },
    children: [
      {
        name: 'my',
        path: '/my',
        component: () => import('_p/my'),
        meta: {
          title: '我的'
        }
      },
      {
        name: 'order',
        path: '/my/order',
        component: () => import('_p/my/order'),
        meta: {
          title: '我的订单',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'createorder',
        path: '/my/order/create',
        component: () => import('_p/my/order/create'),
        meta: {
          title: '填写订单',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'payResult',
        path: '/my/order/payResult/:orderId/:outTradeNo?',
        component: () => import('_p/my/order/create/PayResult'),
        beforeEnter(to, from, next) {
          if (!Bus.isApp && to.query.app) {
            location.href = `${to.query.app}://`
            next(false)
          } else next()
        },
        meta: {
          title: '付款结果',
          needLogin: true,
          back: 'home'
        }
      },
      {
        path: '/my/order/detail/:orderId',
        component: () => import('_p/my/order/detail'),
        meta: {
          title: '订单详情',
          needLogin: true,
          nofooter: true
        }
      },
      {
        path: '/my/order/cancel/:orderId',
        component: () => import('_p/my/order/cancel'),
        meta: {
          title: '申请取消订单',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'orderCancelDetail',
        path: '/my/order/cancel/detail/:orderId',
        component: () => import('_p/my/order/cancel/detail'),
        meta: {
          title: '退款详情',
          needLogin: true,
          nofooter: true
        }
      },
      {
        path: '/my/order/logistics/list/:orderId',
        component: () => import('_p/my/order/logistics/list'),
        meta: {
          title: '物流列表',
          needLogin: true,
          nofooter: true
        }
      },
      {
        path: '/my/order/logistics/detail/:orderNo/:logisticsNo',
        component: () => import('_p/my/order/logistics/detail'),
        meta: {
          title: '物流信息',
          needLogin: true,
          nofooter: true
        }
      },
      {
        path: '/my/order/appraise/:orderNo',
        component: () => import('_p/my/order/appraise/index'),
        meta: {
          trackTitle: '评价商品列表',
          title: '评价',
          needLogin: true,
          nofooter: true
        }
      },
      {
        path: '/my/order/appraise/edit/:orderItemId',
        component: () => import('_p/my/order/appraise/edit'),
        meta: {
          title: '评价',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'afterSalesType',
        path: '/my/order/afterSales/type',
        component: () => import('_p/my/order/afterSales/type'),
        meta: {
          title: '选择售后类型',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'afterSalesExplain',
        path: '/my/order/afterSales/explain',
        component: () => import('_p/my/order/afterSales/explain'),
        meta: {
          title: '退换货说明',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'afterSalesApply',
        path: '/my/order/afterSales/apply',
        component: () => import('_p/my/order/afterSales/apply'),
        meta: {
          title: '申请退款',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'afterSalesList',
        path: '/my/order/afterSales/list/:orderItemId',
        component: () => import('_p/my/order/afterSales/list'),
        meta: {
          title: '售后记录',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'afterSalesDetail',
        path: '/my/order/afterSales/detail/:orderAfterSalesProductId',
        component: () => import('_p/my/order/afterSales/detail'),
        meta: {
          title: '售后详情',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'afterSalesHistory',
        path: '/my/order/afterSales/history/:orderAfterSalesId',
        component: () => import('_p/my/order/afterSales/history'),
        meta: {
          title: '售后历史',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'afterSalesCancel',
        path: '/my/order/afterSales/cancel/:orderId',
        component: () => import('_p/my/order/cancel/detail'),
        meta: {
          title: '退款详情',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'address',
        path: '/my/address',
        component: () => import('_p/my/address'),
        meta: {
          title: '我的收货地址',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'addressAdd',
        path: '/my/address/add',
        component: () => import('_p/my/address/Edit'),
        meta: {
          title: '新增收货地址',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'addressEdit',
        path: '/my/address/edit/:id?',
        component: () => import('_p/my/address/Edit'),
        meta: {
          title: '编辑收货地址',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'myCoupon',
        path: '/my/coupon',
        component: () => import('_p/my/coupon'),
        meta: {
          title: '我的优惠券',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'myUseCoupon',
        path: '/my/coupon/use/:couponId/:couponHistoryId?',
        component: () => import('_p/my/coupon/usecoupon'),
        meta: {
          title: '优惠券适用商品',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'commission',
        path: '/my/distribution/commission',
        component: () => import('_p/my/distribution/commission'),
        meta: {
          title: '佣金明细',
          needLogin: true,
          nofooter: true,
          headerColor: true
        }
      },
      {
        name: 'commissionDetail',
        path: '/my/distribution/commission/detail/:id',
        component: () => import('_p/my/distribution/commission/detail'),
        meta: {
          title: '提现详情',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'commissionFailDetail',
        path: '/my/distribution/commission/failDetail/:id',
        component: () => import('_p/my/distribution/commission/failDetail'),
        meta: {
          title: '佣金退回详情',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'commissionRules',
        path: '/my/distribution/commission/rules',
        component: () => import('_p/my/distribution/commission/rules'),
        meta: {
          title: '使用规则',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'walletGiveRule',
        path: '/my/wallet/giveRule',
        component: () => import('_c/couponRule'),
        meta: {
          title: '使用规则', // 优惠券转赠规则
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'myHelp',
        path: '/my/help',
        component: () => import('_p/my/help'),
        meta: {
          title: '帮助中心'
        }
      },
      {
        name: 'service',
        path: '/my/service',
        beforeEnter(to, from, next) {
          let path = `${sevH5Url}/sevapp`
          const { token, appVersion = '', navigationBarHeight = 0 } = store.state.user
          let defaultParams = `sid=${token}&souce=4&v=${appVersion}&h=${navigationBarHeight}`
          if (to.query.path) path += to.query.path
          path += `${/\?/.test(path) ? '&' : '?'}${defaultParams}`
          location.href = path
          next(false)
        },
        meta: {
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'selectOrder',
        path: '/my/invoice/select',
        component: () => import('_p/my/invoice/select'),
        meta: {
          title: '选择订单',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'createInvoice',
        path: '/my/invoice/create',
        component: () => import('_p/my/invoice/create'),
        meta: {
          title: '开具发票',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'invoiceHistory',
        path: '/my/invoice/history',
        component: () => import('_p/my/invoice/history'),
        meta: {
          title: '开票历史',
          headerColor: true,
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'invoiceDetail',
        path: '/my/invoice/detail/:id',
        component: () => import('_p/my/invoice/detail'),
        meta: {
          title: '发票详情',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'invoiceExplain',
        path: '/my/invoice/explain',
        component: () => import('_p/my/invoice/explain'),
        meta: {
          title: '开票说明'
        }
      },
      {
        name: 'invoicePdf',
        path: '/my/invoice/pdf',
        component: () => import('_p/my/invoice/pdf'),
        meta: {
          title: '查看电子发票' //小程序端查看发票页面调用h5链接地址，如果有修改需要同步修改小程序查看电子发票页
        }
      },
      {
        name: 'decorationQuote',
        path: '/my/decoration/quote',
        component: () => import('_p/my/decoration/quote'),
        meta: {
          title: '免费设计',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'decorationContrast',
        path: '/my/decoration/contrast',
        component: () => import('_p/my/decoration/contrast'),
        meta: {
          title: '家装对比价',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'decorationWxservice',
        path: '/my/decoration/wxservice',
        component: () => import('_p/my/decoration/wxservice'),
        meta: {
          title: '微信客服'
        }
      },
      {
        name: 'activity',
        path: '/my/activity',
        component: () => import('_p/my/activity'),
        meta: {
          title: '好礼共享',
          needLogin: true,
          nofooter: true,
          // headerColor: true
          isTransparent: true
        }
      },
      {
        name: 'activityInfo',
        path: '/my/activity/info',
        component: () => import('_p/my/activity/info'),
        meta: {
          title: '好礼共享', // 好礼共享-活动主页面
          needLogin: true,
          nofooter: true,
          // headerColor: true
          isTransparent: true
        }
      },
      {
        name: 'activityDetail',
        path: '/my/activity/detail',
        component: () => import('_p/my/activity/detail'),
        meta: {
          title: '活动详情', // 好礼共享-活动详情
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'activityHistory',
        path: '/my/activity/history',
        component: () => import('_p/my/activity/history'),
        meta: {
          title: '活动历史', // 好礼共享-活动历史
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'activityPrize',
        path: '/my/activity/prize',
        component: () => import('_p/my/activity/prize'),
        meta: {
          title: '我的奖品', // 好礼共享-我的奖品
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'giftPartner',
        path: '/my/giftPartner',
        component: () => import('_p/my/giftPartner'),
        meta: {
          title: '好礼合伙人',
          needLogin: true,
          nofooter: true,
          // headerColor: true
          isTransparent: true
        }
      },
      {
        name: 'giftPartnerInfo',
        path: '/my/giftPartner/info',
        component: () => import('_p/my/giftPartner/info'),
        meta: {
          title: '组团活动', // 好礼合伙人-活动页
          needLogin: true,
          nofooter: true,
          // headerColor: true
          isTransparent: true
        }
      },
      {
        name: 'giftPartnerRule',
        path: '/my/giftPartner/rule',
        component: () => import('_p/my/giftPartner/rule'),
        meta: {
          title: '活动规则', // 好礼合伙人-活动规则
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'giftPartnerPrizeDesc',
        path: '/my/giftPartner/prizeDesc',
        component: () => import('_p/my/giftPartner/prizeDesc'),
        meta: {
          title: '奖品说明', // 好礼合伙人-奖品说明
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'giftPartnerPrize',
        path: '/my/giftPartner/prize',
        component: () => import('_p/my/giftPartner/prize'),
        meta: {
          title: '我的奖品', // 好礼合伙人-我的奖品
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'giftPartnerGoods',
        path: '/my/giftPartner/watch/goods',
        component: () => import('_p/my/giftPartner/watchTask/List'),
        meta: {
          title: '浏览商品', // 好礼合伙人-浏览商品
          needLogin: true,
          nofooter: true,
          headerColor: true,
          keepAlive: true
        }
      },
      {
        name: 'giftPartnerVideo',
        path: '/my/giftPartner/watch/video',
        component: () => import('_p/my/giftPartner/watchTask/VideoList'),
        meta: {
          title: '观看视频', // 好礼合伙人-观看视频
          needLogin: true,
          nofooter: true,
          headerColor: true,
          keepAlive: true
        }
      },
      {
        name: 'giftPartnerVideoPlayer',
        path: '/my/giftPartner/watch/player',
        component: () => import('_p/my/giftPartner/watchTask/VideoPlay'),
        meta: {
          title: '观看视频', // 好礼合伙人-观看视频-视频播放页
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'distribution',
        path: '/my/distribution',
        component: () => import('_p/my/distribution'),
        meta: {
          title: '推广返佣',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'distributionRule',
        path: '/my/distribution/rule',
        component: () => import('_p/my/distribution/rule'),
        meta: {
          title: '规则说明',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'distributionWithdraw',
        path: '/my/distribution/withdraw',
        component: () => import('_p/my/distribution/withdraw'),
        meta: {
          title: '提现申请',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'distributionOrder',
        path: '/my/distribution/order',
        component: () => import('_p/my/distribution/order'),
        meta: {
          title: '推广订单',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'distributionFans',
        path: '/my/distribution/fans',
        component: () => import('_p/my/distribution/fans'),
        meta: {
          title: '我的粉丝',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'distributionFansDetail',
        path: '/my/distribution/fans/detail/:userId',
        component: () => import('_p/my/distribution/fanDetail'),
        meta: {
          title: '个人佣金明细',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'authenticationUpgradation',
        path: '/my/distribution/authentication/up/:authCode',
        component: () => import('_p/my/distribution/authenticationUp'),
        meta: {
          title: '认证申请',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'authenticationDetails',
        path: '/my/distribution/authentication/details',
        component: () => import('_p/my/distribution/authenticationDetails'),
        meta: {
          title: '认证详情',
          needLogin: true,
          nofooter: true
        }
      },
      {
        name: 'myFlashSale',
        path: '/my/flashSale',
        component: () => import('_p/my/flashSale'),
        meta: {
          title: '限时秒杀',
          // needLogin: true,
          // isTransparent: true,
          nofooter: true
        }
      },
      {
        name: 'groupBuy',
        path: '/my/groupBuy',
        component: () => import('_p/my/groupBuy'),
        meta: {
          title: '拼团活动',
          nofooter: true
        }
      },
      {
        name: 'groupBuyRule',
        path: '/my/groupBuy/rule',
        component: () => import('_p/my/groupBuy/rule'),
        meta: {
          title: '规则说明',
          nofooter: true
        }
      },
      {
        name: 'groupBuyRecord',
        path: '/my/groupBuy/record',
        component: () => import('_p/my/groupBuy/record'),
        meta: {
          title: '拼团记录',
          nofooter: true
        }
      },
      {
        name: 'groupBuyDetail',
        path: '/my/groupBuy/detail/:orderId',
        component: () => import('_p/my/groupBuy/detail'),
        meta: {
          title: '拼团详情',
          nofooter: true
        }
      }
    ]
  }
]
