export default {
  getGiftBrands: {
    title: '获取到赠送的品牌',
    url: '/api/shop/promotion/getGiftBrands'
  },
  getGiftGoods: {
    title: '赠送商品列表',
    method: 'post',
    url: '/api/shop/promotion/listGoodsForBrandIds',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // 接口：参数不传即获取赠品信息；只传selected即全选或全不选
  switchSelectedOrGetGiftInfo: {
    title: '更新选中状态/获取赠品信息',
    url: '/api/shop/cart/shoppingCartGift'
  }
}
